import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  email: null,
  last_login: null,
  access_token: localStorage.getItem("auth_token"),
  refresh_token: localStorage.getItem("refresh_token"),
  isLogin: localStorage.getItem("auth_token") ? true : false,
  currentStep: 0,
  video_Id: null,
  updatedX: 0,
  updatedY: 0,
  updatedTextareaWidth: 0,
  updatedtextareaHeight: 0,
  loading: false,
  video_file: null,
  isFetchedData: false,
  video_id_redux: null,

  // video  font state  ;
  fontSize: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, user) => {
      state.id = user.payload.id;
      state.email = user.payload.email;
      state.last_login = user.payload.last_login;
      state.access_token = user.payload.access_token;
      state.refresh_token = user.payload.refresh_token;
      state.isLogin = true;
      localStorage.setItem("auth_token", user.payload.access_token);
      localStorage.setItem("refresh_token", user.payload.refresh_token);
    },
    resetState: (state, user) => {
      state.id = null;
      state.email = null;
      state.last_login = null;
      state.access_token = localStorage.getItem("auth_token");
      state.refresh_token = localStorage.getItem("refresh_token");
      state.isLogin = localStorage.getItem("auth_token") ? true : false;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload; // Use action.payload to update the current step
    },
    setVideoId: (state, action) => {
      state.video_Id = action.payload; // Use action.payload to update the current step
    },
    setVideoIdRedux: (state, action) => {
      state.video_id_redux = action.payload; // Use action.payload to update the current step
    },
    setfetched: (state, action) => {
      state.isFetchedData = action.payload; // Use action.payload to update the current step
    },
    setUpdatedX: (state, action) => {
      state.updatedX = action.payload; // Use action.payload to update the value of X
    },
    setUpdatedY: (state, action) => {
      state.updatedY = action.payload; // Use action.payload to update the value of Y
    },
    setUpdatedTextAreaWidth: (state, action) => {
      state.updatedTextareaWidth = action.payload; // Use action.payload to update the value of textareawidth
    },
    setUpdatedTextAreaHeight: (state, action) => {
      state.updatedtextareaHeight = action.payload; // Use action.payload to update the value of textareaHeight
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVideoFile: (state, action) => {
      state.video_file = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setUserData } = authSlice.actions;
export const { setCurrentStep } = authSlice.actions;
export const { setVideoId } = authSlice.actions;
export const { setVideoIdRedux } = authSlice.actions;
export const { setUpdatedX } = authSlice.actions;
export const { setUpdatedY } = authSlice.actions;
export const { setUpdatedTextAreaWidth } = authSlice.actions;
export const { setUpdatedTextAreaHeight } = authSlice.actions;
export const { resetState } = authSlice.actions;
export const { setLoading } = authSlice.actions;
export const { setVideoFile } = authSlice.actions;
export const { setfetched } = authSlice.actions;
export const setUserStore = (state) => state.setUserData;
export default authSlice.reducer;
