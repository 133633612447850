import React, { useCallback, useEffect, useState } from "react";
import { Button, Steps } from "antd";
import UploadVideo from "./upload-video-stepper/UploadVideo";
import UploadExcel from "./UploadExcel";
import PaymentButton from "./upload-video-stepper/paymentButton";
import Payment from "./Payment";
import "./stepper.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setFontSize, setFont, setColor } from "../../../store/slices/textStylesSlice";
import { setVideoFile, setfetched, setVideoIdRedux } from "../../../store/slices/authSlice";
import { Spin } from 'antd';
import moment from 'moment';
import dayjs from "dayjs";

const Stepper = ({ current, currentStepsIndex, setCurrent, setCurrentStepsIndex }) => {
  const dispatch = useDispatch();

  const initState = {
    csv_file: {},
    video_file: {},
    x: 0,
    y: 0,
    font_type: "",
    font_color: "",
    start_clip: 0,
    end_clip: 0,
    font_size: 0,
    width_box: 0,
    height_box: 0,
  };

  const { currentStep, video_Id } = useSelector((state) => state.auth);

  const [loading, setIsloading] = useState(false);

  const userEmail = localStorage.getItem("userEmail");
  const emailList = process.env.REACT_APP_EMAIL_LIST.split(",");
  const isPaymentExpected = !emailList.includes(userEmail);

  useEffect(() => {
    if (currentStep && video_Id) {
      setIsloading(true);
      axios
        .get(process.env.REACT_APP_DEV_URL + "api/video-details", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
          params: {
            video_id: parseInt(video_Id),
          },
        })
        .then((res) => {
          const data = res.data[0];
          setVideoData(prevState => ({
            ...prevState,
            font_color: data.font_color,
            font_type: data.font_type,
            font_size: data.font_size,
            width_box: data.width_box,
            height_box: data.height_box,
            x: data.x,
            y: data.y,
            start_clip: data.start_clip,
            end_clip: data.end_clip,
            startTime: dayjs((new Date(data.start_clip * 1000)).setHours(0,data.start_clip/60,data.start_clip%60,0)),   
            endTime: dayjs(new Date(data.end_clip * 1000).setHours(0,data.end_clip/60,data.end_clip%60,0))
          }));
          dispatch(setFontSize(data.font_size));
          dispatch(setFont(data.font_type));
          dispatch(setColor(data.font_color));
          dispatch(setVideoIdRedux(data.id));
          const file_name = data.main_name;
          axios.get(process.env.REACT_APP_DEV_URL +`api/video/${data.id}`, 
	        {
            responseType: 'arraybuffer',
          })
            .then((response) => {
              const blob = new Blob([response.data], { type: 'video/mp4' });
              updateVideoData("video_file", new File([response.data], file_name, {type: 'video/mp4'}));
              dispatch(setVideoFile(URL.createObjectURL(blob)));
              dispatch(setfetched(true));
              setIsloading(false);
            })
            .catch((error) => { setIsloading(false); console.log(error); });
        })
        .catch((error) => {
          console.log(error);
          setIsloading(false);
        })
    }
  }, [currentStep, video_Id]);

  const [videoId, setVideoId] = useState(null);
  const [videoData, setVideoData] = useState({
    csv_file: null,
    video_file: null,
    x: 0,
    y: 0,
    font_type: "Arial",
    font_color: "#000000",
    start_clip: 0,
    end_clip: 0,
    font_size: 10,
    width_box: 0,
    height_box: 0,
  });

  const updateVideoData = useCallback((key, value) => {
    setVideoData(prevState => ({
      ...prevState,
      [key]: value
    }));
  }, [])

  const [rowCount, setrowCount] = React.useState(0);

  const next = () => {
    const newStep = currentStepsIndex + 1;
    if (newStep >= 0 && newStep <= 2) {
      // !fix this set state
      setCurrentStepsIndex(prevState => prevState + 1)
      // dispatcher(setCurrentStep(newStep));
    }
  };

  const prev = () => {
    const newStep = currentStepsIndex - 1;
    if (newStep >= 0 && newStep <= 2) {
      setCurrentStepsIndex(prevState => prevState - 1)
    }
  };


  const steps = [
    {
      title: "Upload Video",
      content: (
        <UploadVideo
          updateVideoData={updateVideoData}
          videoData={videoData}
          next={next}
        />
      ),
    },
    {
      title: "Upload CSV File",
      content: (
        <UploadExcel
          rowCount={rowCount}
          setRowCount={setrowCount}
          setVideoId={setVideoId}
          updateVideoData={updateVideoData}
          videoData={videoData}
          setVideoData={setVideoData}
          next={next}
        />
      ),
    }
  ];

  if(isPaymentExpected){
    steps.push({
      title: "Make Payment",
      content: <Payment rowCount={rowCount} videoId={videoId} />,
    });
  }

  const items = steps?.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
    <Spin spinning={loading}>
      <div className="stepper-container">
        <Steps current={currentStepsIndex} items={items} className="stepper" />
      </div>
      <div style={{ marginTop: "20px" }}>{steps[currentStepsIndex].content}</div>
      <div className="button-container">
        {/* {currentStepsIndex == 1 && (
          <Button
            className="prev-button"
            style={{ marginLeft: "10px" }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )} */}

        {currentStepsIndex !== 0 && (
          <Button
            className="prev-button"
            style={{ marginLeft: "10px" }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}

        {isPaymentExpected && currentStepsIndex === steps.length - 1 && <PaymentButton videoId={videoId} />}
      </div>
      {isPaymentExpected && currentStepsIndex === 2 && <div style={{ height: "350px" }} />}
      </Spin>
    </>
  );
};

export default Stepper;
