import React from "react";
import "./login.css";
import Header from "../layout/Header/Header";
import { Image, Tabs } from "antd";
import logo from "../../assets/images/smalllogo.svg";

import LoginTab from "./LoginTab";
import RegisterTab from "./RegisterTab";

const Login = () => {
  const [selectedTab, setselectedTab] = React.useState("1");
  const tabs = [
    {
      key: "1",
      label: `Login`,
    },
    {
      key: "2",
      label: `Register`,
    },
  ];
  return (
    <>
      <Header />
      <div className="login-container">
        <div className="form-container">
          <Image
            src={logo}
            alt="small-logo"
            preview={false}
            style={{ marginBottom: "50px" }}
          />
          <div className="tab-container">
            <Tabs
              items={tabs}
              className="tab"
              onChange={(tabVal) => {
                setselectedTab(tabVal);
              }}
            ></Tabs>
          </div>
          {selectedTab === "1" ? <LoginTab /> : <RegisterTab />}
        </div>
      </div>
    </>
  );
};

export default Login;
