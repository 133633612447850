import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import textStyleSlice from './slices/textStylesSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    textStyle: textStyleSlice,
    devTools: true,
  },
});
