import React from "react";
import { Typography, Col, List, Image, Button, notification } from "antd";
import sampleVideo from "../../assets/images/samplevideo.png";
import noFound from "../../assets/icons/no_found.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const StatusCard = () => {
  const [success, setsuccess] = React.useState([]);

  const [in_process, setinprocess] = React.useState([]);
  const [in_queue, setinqueue] = React.useState([]);

  const navigate = useNavigate();

  const navigateToDetails = (id) => {
    const url = "/task-status/details/" + id;
    window.open(url, "_blank");
  };

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_DEV_URL + "api/videos", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        params: {
          status: "completed"
        }
      })
      .then((res) => {
        setsuccess(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login"); // Replace with your login page URL
          window.location.reload();
          return; // Exit the function to prevent further processing
        }
        notification.error({ message: err });
      });

    axios
      .get(process.env.REACT_APP_DEV_URL + "api/videos", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        params: {
          status: "inprogress",
        }
      })
      .then((res) => {
        setinprocess(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login"); // Replace with your login page URL
          window.location.reload();
          return; // Exit the function to prevent further processing
        }
        notification.error({ message: err });
      });

    axios
      .get(process.env.REACT_APP_DEV_URL + "api/videos", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        params: {
          status: "unprocessed",
        }
      })
      .then((res) => {
        setinqueue(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login"); // Replace with your login page URL
          window.location.reload();
          return; // Exit the function to prevent further processing
        }
        notification.error({ message: err });
      });
  }, []);

  const statusCardDetails = [
    {
      title: "Inqueue",
      backColor: "var(--inqueue-color)",
      textColor: "#FBC450",
      list: in_queue,
    },
    {
      title: "In Progress",
      backColor: "var(--progress-color)",
      textColor: "#4BAAFF",
      list: in_process,
    },
    {
      title: "Completed",
      backColor: "var(--success-color)",
      textColor: "#90DA69",
      list: success,
    },
  ];
  return (
    <>
      {statusCardDetails?.map((card, index) => (
        <Col lg={8} xs={24} key={index}>
          {card.title === "Completed" && (
            <div className="status-container">
              <div
                className="status-header"
                style={{ background: card.backColor, color: card.textColor }}
              >
                {card.title}
              </div>
              <div className="status-body">
                {card.list.length > 0 ? (
                  <List
                    itemLayout="horizontal"
                    dataSource={success}
                    renderItem={(item, index) => (
                      <List.Item key={index} className="status-list">
                        <List.Item.Meta
                          title={
                            <Typography.Text>{item.main_name}</Typography.Text>
                          }
                          description={
                            <>
                              <div className="discWrapper">
                                <div>
                                  Time:{" "}
                                  {moment
                                    .utc(item.time, "HH:mm:ss")
                                    .local()
                                    .format("hh:mm A")}
                                </div>
                                <div>
                                  Date:{" "}
                                  {item.date}
                                </div>
                                <Button
                                  className="view-details-btn"
                                  onClick={() => navigateToDetails(item.id)}
                                  style={{
                                    marginRight: "135px",
                                    marginTop: "5px",
                                    width: "112px",
                                  }}
                                >
                                  View details
                                </Button>
                              </div>
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <div className="no-found-container">
                    <Image src={noFound} alt="not found" preview={false} />
                    <div>Opps !! There are no files here !</div>
                  </div>
                )}
              </div>
            </div>
          )}

          {card.title === "In Progress" && (
            <div className="status-container">
              <div
                className="status-header"
                style={{ background: card.backColor, color: card.textColor }}
              >
                {card.title}
              </div>
              <div className="status-body">
                {card.list.length > 0 ? (
                  <List
                    itemLayout="horizontal"
                    dataSource={in_process}
                    renderItem={(item, index) => (
                      <List.Item key={index} className="status-list">
                        <List.Item.Meta
                          title={
                            <Typography.Text>{item.video_name}</Typography.Text>
                          }
                          description={
                            <>
                              <div style={{ float: "left" }}>
                                <div>
                                  Upload Time:{" "}
                                  {moment
                                    .utc(item.upload_time, "HH:mm:ss")
                                    .local()
                                    .format("hh:mm A")}
                                </div>
                              </div>
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <div className="no-found-container">
                    <Image src={noFound} alt="not found" preview={false} />
                    <div>Opps !! There are no files here !</div>
                  </div>
                )}
              </div>
            </div>
          )}

          {card.title === "Inqueue" && (
            <div className="status-container">
              <div
                className="status-header"
                style={{ background: card.backColor, color: card.textColor }}
              >
                {card.title}
              </div>
              <div className="status-body">
                {card.list.length > 0 ? (
                  <List
                    itemLayout="horizontal"
                    dataSource={in_queue}
                    renderItem={(item, index) => (
                      <List.Item key={index} className="status-list">
                        <List.Item.Meta
                          title={
                            <Typography.Text>{item.video_name}</Typography.Text>
                          }
                          description={
                            <>
                              <div style={{ float: "left" }}>
                                <div>
                                  Upload Time:{" "}
                                  {moment
                                    .utc(item.upload_time, "HH:mm:ss")
                                    .local()
                                    .format("hh:mm A")}
                                </div>
                              </div>
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <div className="no-found-container">
                    <Image src={noFound} alt="not found" preview={false} />
                    <div>Opps !! There are no files here !</div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
      ))}
    </>
  );
};

export default StatusCard;
