import React, { useEffect, useState } from "react";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Tag, Typography, notification, Menu, Dropdown } from "antd";
import moment from "moment";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { setCurrentStep, setVideoId } from "../../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import "./GuestlistTable.css";

function GuestlistTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [guestList, setGuestList] = useState([]);
  const [selectedDownloadOption, setSelectedDownloadOption] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    // Fetch guest list data
    axios
      .get(process.env.REACT_APP_DEV_URL + "api/completed-guest-task/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        const apiResponse = res.data.data;
        if (Array.isArray(apiResponse)) {
          setGuestList(apiResponse);
        } else {
          setGuestList([]);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          notification.error({ message: err });
        }
      });
  }, [id, navigate]);

  const navigateToStepTwo = () => {
    dispatch(setVideoId(id));
    dispatch(setCurrentStep(1));
    navigate("/edit-video");
  };

  // Prepare data for the table
  const data = guestList?.map((guest, index) => ({
    key: index,
    id: guest.id,
    video: guest.guest_name,
    completedTime: moment.utc(guest.time, "HH:mm:ss").local().format("hh:mm A"),
    completedDate: guest.date,
    status: "Completed",
    action: "Download",
    s3_url: guest.s3_url,
    main_video_date: guest.main_video_date,
  }));

  // Check if main_video_date is more than 30 days old
  const isMainVideoDateOld = (data) => {
    if (data && data.length > 0) {
      // Use the correct date format "DD-MM-YYYY"
      const parsedDate = moment(data[0].main_video_date, "DD-MM-YYYY");
      const thirtyDaysAgo = moment().subtract(30, "days");

      const isDateOld = parsedDate.isBefore(thirtyDaysAgo);

      return isDateOld;
    }
    return false; // Return false if data is empty or undefined
  };

  const handleDownloadVideosAsIndividuals = (rec_id) => {
    if (rec_id !== undefined) {
      setIsDownloading(true);
      notification.success({
        message: "Video File Download Started",
        description:
          "Please wait while the Video file is being downloaded.",
      });
      // Iterate through the data array and download each video

      // Assuming there is an 'id' field in your data
      const url = `${process.env.REACT_APP_DEV_URL}api/download_video/?id=${rec_id}`;

      // Create a GET request to fetch the video data as a blob
      axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
        .then(async (response) => {
          console.log(response.data, "test_response");
          const link = document.createElement('a');
          const regex = /([^/]+)(?=\.[^.]+$)/;
          const match = response.data.match(regex);

          if (match && match[1]) {
              let static_path = `${process.env.REACT_APP_DEV_URL}media/processed-videos/${match[1]}.mp4`;
              

              const response = await fetch(static_path, {
                method: 'GET',
              });
        
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
        
              const blob = await response.blob();
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = `${match[1]}.mp4`; // Specify the download filename
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            setIsDownloading(false);
          } else {
              console.error("Failed to match the expected pattern in response data.");
          }


        })
        .catch((err) => {
          console.log(err.message);
          if(err.response.status === 400){
            notification.error({message: "New videos are under process can download zip now!!"})
          }
          else{
            notification.error({ message: err.message });
          }
        });

      // Wait for all download promises to resolve

      setIsDownloading(false);
    } else {
      setIsDownloading(true);
      notification.success({
        message: "ZIP File Download Started",
        description:
          "Please wait while the ZIP file is being prepared and downloaded.",
      });

      // Modify the URL based on whether it's a bulk download or individual download
      const url = `${process.env.REACT_APP_DEV_URL}api/download_video/?is_bulk_download=true&id=${id}`

      axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
          .then((response) => {
            const filenameWithExt = response.data.split('/').pop();
            // Remove the extension to get the filename
            const filenameWithoutExt = filenameWithExt.split('.').slice(0, -1).join('.');
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_DEV_URL}media/zipfiles/${filenameWithoutExt}.zip`;
            link.download = `${filenameWithoutExt}.zip`;
            link.style.display = 'none';
            link.target = '';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsDownloading(false);
        })
        .catch((err) => {
          console.log(err.message);
          if(err.response.status === 400){
            notification.error({message: "New videos are under process can not download zip now!!"})
          }
          else{
            notification.error({ message: err.message });
          }
          // Set loading to false on error
          setIsDownloading(false);
        });
    }
  };

  const handleDownloadOptionClick = (e) => {
    setSelectedDownloadOption(e.key);
    handleDownloadVideosAsIndividuals();
  };

  const menu = (
    <Menu onClick={handleDownloadOptionClick}>
      <Menu.Item key="zip">Download as ZIP</Menu.Item>
    </Menu>
  );

  const handleDownloadCSV = () => {
    axios({
      url:
        process.env.REACT_APP_DEV_URL +
        "api/completed-guest-filename-csv-download/" +
        id,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const a = document.createElement("a");
        a.href = url;
        a.download = "Guest Name & Video File Name.csv";
        a.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          notification.error({ message: "Your new video is processing and the previous videos are deleted so no csv is found!!" });
        }
        else{
          notification.error({ message: "Something went wrong!"});
        }
        // notification.error({ message: "Your new video is processing and the previous videos are deleted so no csv is found!!" });
      });
  };

  const columns = [
    {
      title: "Names",
      dataIndex: "video",
      render: (guestName, record) => (
        <>
          <div className="video-details">
            <Typography.Text>{guestName}</Typography.Text>
          </div>
        </>
      ),
    },
    {
      title: "Complete Time",
      dataIndex: "completedTime",
    },
    {
      title: "Complete Date",
      dataIndex: "completedDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: () => (
        <>
          <Tag color={"success"}>Success</Tag>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <>
            <Button
              className="table-download-btn"
              onClick={() => {
                handleDownloadVideosAsIndividuals(record.id);
              }}
            >
              <DownloadOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {isDownloading && <div className="blur-overlay"></div>}
      <div className="guestlist-container">
        <div className="title-text">List of Videos</div>
        <div className="buttons">
          {isMainVideoDateOld(data) ? null : (
            <Button
              className="upload-excel"
              icon={<UploadOutlined />}
              onClick={navigateToStepTwo}
            >
              Upload New CSV
            </Button>
          )}
          <Button className="download" onClick={handleDownloadCSV}>
            <DownloadOutlined />
            Download CSV
          </Button>
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button className="download">
              <DownloadOutlined /> Download All Videos
            </Button>
          </Dropdown>
        </div>
        <div className="content-table">
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </>
  );
}

export default GuestlistTable;
