import React from "react";
import "./index.css";
import sampleVideo from "../../assets/images/samplevideo.png";
import { Typography, Col, List, notification } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";

const TaskResult = () => {
  const { id } = useParams();
  const [success, setsuccess] = React.useState([]);
  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_DEV_URL + "api/main-video/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        setsuccess(res.data);
      })
      .catch((err) => {
        notification.error({ message: err });
      });
  }, []);

  const statusCardDetails = [
    {
      backColor: "var(--success-color)",
      textColor: "#90DA69",
      list: success,
    },
  ];
  return (
    <>
      <div className="task-result">Task Status</div>
      {statusCardDetails?.map((index) => (
        <Col lg={30} xs={100} key={index}>
          <div className="task-status-body">
            <List
              itemLayout="horizontal"
              dataSource={success}
              renderItem={(item, index) => (
                <List.Item key={index} className="status-list">
                  <List.Item.Meta
                    className="list-item"
                    title={
                      <Typography.Text className="header-text">
                        {item.main_name}
                      </Typography.Text>
                    }
                    description={
                      <>
                        <div className="status-wrapper">
                          <div className="desc-text" style={{ float: "left" }}>
                            <div>
                              Date:{" "}
                              {item.date}
                            </div>
                            <div>
                              Upload Time:{" "}
                              {moment
                                .utc(item.time, "HH:mm:ss")
                                .local()
                                .format("hh:mm A")}
                            </div>
                            <div>
                              Complete Time:{" "}
                              {moment
                                .utc(item.completed_time, "HH:mm:ss")
                                .local()
                                .format("hh:mm A")}
                            </div>
                          </div>
                          <div className="status-content">
                            <div className="status-text1">Status :</div>
                            <div className="status-text2"> Completed </div>
                          </div>
                        </div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </Col>
      ))}
    </>
  );
};

export default TaskResult;
