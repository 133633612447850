import React, { useState } from "react";
import {
  LockOutlined,
  UserSwitchOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, Row, Col, notification } from "antd";
import { setUserData } from "../../store/slices/authSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Spin } from 'antd';


const LoginTab = () => {
  const [rememberMe, setrememberMe] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const togglePasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onFinish = (values) => {
    const form_data = new FormData();
    form_data.append("email", values.email);
    form_data.append("password", values.password);
    setLoading(true);
    axios
      .post(process.env.REACT_APP_DEV_URL + "api/user/login", form_data)
      .then((res) => {
        dispatch(setUserData(res?.data?.detail));
        notification.success({ message: "Login successfully !!" });
        localStorage.setItem("userEmail", values.email);
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        notification.error({ message: err?.response?.data?.detail });
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form
        name="login_form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your email id!" },
                { type: "email", message: "Please enter valid email id!" },
              ]}
            >
              <Input
                type="email"
                className="login-input-field"
                placeholder="Email"
                prefix={<UserSwitchOutlined className="icon-color" />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input
                type={!isPasswordVisible ? "password" : "text"}
                className="login-input-field"
                placeholder="Password"
                autoComplete="off"
                prefix={<LockOutlined className="icon-color" />}
                suffix={
                  isPasswordVisible ? (
                    <EyeInvisibleOutlined
                      className="icon-color"
                      onClick={togglePasswordVisible}
                    />
                  ) : (
                    <EyeOutlined
                      className="icon-color"
                      onClick={togglePasswordVisible}
                    />
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="flex-row">
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox
                  defaultChecked={rememberMe}
                  value={rememberMe}
                  onChange={(e) => setrememberMe(e.target.value)}
                  className={"login-txt"}
                  style={{ color: "var(--tab-color)" }}
                >
                  Remember me
                </Checkbox>
              </Form.Item>
              <NavLink
                to="/forgot-password"
                className={"login-txt"}
                style={{ color: "var(--tab-color)" }}
              >
                Forgot Password
              </NavLink>
            </div>
          </Col>
          <Col span={24}>
            <div className="flex-row">
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-btn">
                  Login
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default LoginTab;
