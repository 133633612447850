import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function PublicRoute() {
  const { access_token } = useSelector((state) => state.auth);
  if (access_token) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
}

export default PublicRoute;