// FontDropdown.js
import React from "react";
import { Select } from "antd";

const { Option } = Select;

function FontDropdown({ fonts, selectedFont, onChange }) {
return (
    <Select
    value={selectedFont}
    style={{
        width: 200,
        height: 32,
        backgroundColor: "#ffffff",
        border: "1px solid #d9d9d9",
    }}
    onChange={(value) => onChange(value)} // Pass the selected value directly
    showSearch
    placeholder="Select Font"
    optionFilterProp="children"
    >
    {fonts?.map((font, index) => (
    <Option key={index} value={font}>
    {font}
    </Option>
    ))}
    </Select>
);
}

export default FontDropdown;
