export function direction(windowWidth) {
  if (windowWidth >= 576) {
    return "horizontal";
  } else {
    return "vertical";
  }
}

// disabled the start and end time

export const disabledHours = (endvideoTime) => {
  // Disable all hours except 0
  return Array.from({ length: 24 }, (_, i) =>
    i <= endvideoTime?.split(":")[0] ? null : i
  ).filter((h) => h !== null);
};

export const disabledMinutes = (endvideoTime) => {
  return Array.from({ length: 60 }, (_, i) =>
    i <= endvideoTime?.split(":")[1] ? null : i
  ).filter((h) => h !== null);
};

export const disabledSeconds = (endvideoTime) => {
  return Array.from({ length: 60 }, (_, i) =>
    i <= endvideoTime?.split(":")[2] ? null : i
  ).filter((h) => h !== null);
};
