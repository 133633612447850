import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    color: "#000000",
    fontSize: "10",
    font: "Arial",
    fontStyle: "normal",
};

export const textStyleSlice = createSlice({
  name: "textStyle",
  initialState,
  reducers: {
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setFontSize: (state, action) => {
      state.fontSize = action.payload;
    },
    setFont: (state, action) => {
      state.font = action.payload; 
    },
    setFontStyle: (state, action) => {
      state.fontStyle = action.payload;
    }
  },
  extraReducers: (builder) => {},
});

export const { setColor,setFontSize,setFontStyle,setFont } = textStyleSlice.actions;
export default textStyleSlice.reducer;
