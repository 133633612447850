// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .main-container{
    min-height: "100vh";
} */
/* .footer{
    position: sticky;
   
} */`, "",{"version":3,"sources":["webpack://./src/components/upload-pdf/index.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;;;GAGG","sourcesContent":["/* .main-container{\n    min-height: \"100vh\";\n} */\n/* .footer{\n    position: sticky;\n   \n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
