import React from "react";
import Header from "../layout/Header/Header";
import { Image } from "antd";
import ForgotPasswordTab from "./ForgotPasswordTab";
import logo from "../../assets/images/smalllogo.svg";

const ForgotPassword = () => {
  return (
    <>
      <Header />
      <div className="login-container">
        <div className="form-container">
          <Image
            src={logo}
            alt="small-logo"
            preview={false}
            style={{ marginBottom: "50px" }}
          />
          <div className="tab-container">Forgot Password</div>
          <ForgotPasswordTab />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
