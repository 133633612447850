import ForgotPassword from "./components/forgot-password/ForgotPassword";
import Login from "./components/login-register/Login";
import UploadVideoIndex from "./components/upload-video";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import DashboardMain from "./components/task-status";
import GuestListMain from "./components/task-status/video-details";
import LandingPage from "./components/landing-page/LandingPage";
import UploadPDFIndex from "./components/upload-pdf";
import MainDashboardPDFStatus from "./components/task-status-pdf";
import RowListMain from "./components/task-status-pdf/pdf-details";

// const auth_token = localStorage.getItem("auth_token");


export const routes = [
  {
      path:"/",
      element:<PrivateRoute/>,
      children:[
          {
            path: "",
            element: <LandingPage />
          },
          {
            path: "/edit-video",
            element: <UploadVideoIndex />
          },
          {
            path: "/edit-pdf",
            element: <UploadPDFIndex />
          },
          {
            path: "task-status",
            element: <DashboardMain />
          },
          {
            path: "task-status/details/:id",
            element: <GuestListMain />
          },
          {
            path: "task-status-pdf",
            element: <MainDashboardPDFStatus />
          },
          {
            path: "task-status-pdf/details/:id",
            element: <RowListMain />
          },
      ]
  },
  { 
        path: "/", 
        element:<PublicRoute/>,
        children:[
              {
                  path: "login",
                  element: <Login />
              },
              {
                  path: "forgot-password",
                  element: <ForgotPassword />
              },
        ],
    }
]
