// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --primary-color : #FE4D8F;
  --tab-color : #7054B6;
  --text-color: #000000;
  --subtext-color : rgba(0, 0, 0, 0.25);
  --tab-back-color: #F0F2F5;
  --inqueue-color:#FFFBE6;
  --progress-color:#E6F7FF;
  --success-color:#F6FFED;
  --poppins-font:Poppins;
}
@font-face {
  font-family: Poppins;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
body{
  background: #FFFFFF;
  margin: 0;
  font-family: var(--poppins-font);
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,qBAAqB;EACrB,qCAAqC;EACrC,yBAAyB;EACzB,uBAAuB;EACvB,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,oBAAoB;EACpB,4CAAiD;AACnD;AACA;EACE,mBAAmB;EACnB,SAAS;EACT,gCAAgC;AAClC","sourcesContent":[":root{\n  --primary-color : #FE4D8F;\n  --tab-color : #7054B6;\n  --text-color: #000000;\n  --subtext-color : rgba(0, 0, 0, 0.25);\n  --tab-back-color: #F0F2F5;\n  --inqueue-color:#FFFBE6;\n  --progress-color:#E6F7FF;\n  --success-color:#F6FFED;\n  --poppins-font:Poppins;\n}\n@font-face {\n  font-family: Poppins;\n  src: url(../src/assets/fonts/Poppins-Regular.ttf);\n}\nbody{\n  background: #FFFFFF;\n  margin: 0;\n  font-family: var(--poppins-font);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
