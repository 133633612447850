import React from 'react';
import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import "./landingPage.css";
import { useDispatch } from 'react-redux';
import { resetState } from '../../store/slices/authSlice';

const LandingPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleUploadVideo = () => {
        navigate("/edit-video");
    };
    const handleUploadPDF = () => {
        navigate("/edit-pdf");
    };
    const handleLogout = () => {
      localStorage.clear();
      dispatch(resetState());
      navigate("/login");
    };

  return (
  <div className='container'>
    <div className="overlay">
      <Button className="logout-btn-landing" onClick={handleLogout}>
        Log out
      </Button>
      <div className="center-container">
        <Space size="middle">
          <div className='redirect-btn'>
          <div className='edit-video-btn'>
            <Button  onClick={handleUploadVideo}>Edit Video</Button>
          </div>
          <div className='edit-pdf-btn'>
            <Button  onClick={handleUploadPDF}>Edit PDF</Button>
          </div>
          </div>
        </Space>
      </div>
      </div>
  </div>
  )
}

export default LandingPage
