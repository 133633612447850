// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-form-container {
    margin-top: 20px;
}

.key-width {
    width: 150px;
}

.form-header-text {
    font-family: var(--poppins-font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.video-container {
    min-width: 350px;
    min-height: 350px;
    max-width: 700px;
    position: relative;
}

.uploaded-video{
    min-width: 350px;
    min-height: 250px;
}

.overlay-textbox {
    position: absolute;
    left: 0;
    width: 219px;
    font-size: 16px;
    border: solid #000000;
    top: 0;
}

@media only screen and (max-width: 480px) {
    .overlay-textbox {
        height: 17.5px;
        left: 10%;
        width: 25%;
    }
}

@media only screen and (max-width: 768px) {
    .overlay-textbox {
        width: 40%;
        height: 17.5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/upload-video/stepper/upload-video-stepper/upload-form.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,MAAM;AACV;;AAEA;IACI;QACI,cAAc;QACd,SAAS;QACT,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;QACV,cAAc;IAClB;AACJ","sourcesContent":[".upload-form-container {\n    margin-top: 20px;\n}\n\n.key-width {\n    width: 150px;\n}\n\n.form-header-text {\n    font-family: var(--poppins-font);\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n}\n\n.video-container {\n    min-width: 350px;\n    min-height: 350px;\n    max-width: 700px;\n    position: relative;\n}\n\n.uploaded-video{\n    min-width: 350px;\n    min-height: 250px;\n}\n\n.overlay-textbox {\n    position: absolute;\n    left: 0;\n    width: 219px;\n    font-size: 16px;\n    border: solid #000000;\n    top: 0;\n}\n\n@media only screen and (max-width: 480px) {\n    .overlay-textbox {\n        height: 17.5px;\n        left: 10%;\n        width: 25%;\n    }\n}\n\n@media only screen and (max-width: 768px) {\n    .overlay-textbox {\n        width: 40%;\n        height: 17.5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
