import React , {useState , memo , useCallback, useEffect,useRef}from 'react'
import { Spin,Steps,Button } from 'antd';
import UploadPDF from './upload-pdf-stepper/UploadPDF';
import UploadCSV from './UploadCSV';
import { useSelector,useDispatch } from 'react-redux';
import axios from 'axios';
import { setfetched } from '../../../store/slices/authSlice';
import PDFPayment from './PDFPayment';
import PDFPaymentButton from './upload-pdf-stepper/PDFPaymentButton';


const Stepper = memo(({  currentStepsIndex, setCurrentStepsIndex }) => {

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const[PdfData,setPdfData]= useState({
      csv_file:{},
      pdf_file:{}
    });
    const [csvrowCount , setCsvrowCount] = useState();
    const pdfDetails = useRef(false);

    const { currentStepPdf, pdfId } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const userEmail = localStorage.getItem("userEmail");
    const emailList = process.env.REACT_APP_EMAIL_LIST.split(",");
    const isPaymentExpected = !emailList.includes(userEmail);

    const updatePdfData = useCallback((key, value) => {
      setPdfData(prevState => ({
        ...prevState,
        [key]: value
      }));
    }, [])

    const next = () => {
      const newStep = currentStepsIndex + 1;
      if (newStep >= 0 && newStep <= 2) {
        setCurrentStepsIndex(newStep)
      }
      
    };
  
    const prev = () => {
      const newStep = currentStepsIndex - 1;
      if (newStep >= 0 && newStep <= 2) {
        setCurrentStepsIndex(newStep)
      }
    };

    const handleScroll = (event) => {
      const { deltaY } = event;
      if (deltaY > 0) {
        setPageNumber((prevPage) => Math.min(numPages, prevPage + 1));
      } else {
        setPageNumber((prevPage) => Math.max(1, prevPage - 1));
      }
    };

    const handleNumberofPages = (event) => {
      setNumPages(event.numPages);
    }

    function transformTagData(tagData) {
      const transformedData = {};
    
      tagData.forEach(tag => {
        const pageNumber = tag.page_number;
        const transformedTag = {
          pageNumber: pageNumber,
          x: tag.x,
          y: tag.y,
          text: tag.text,
          font_size: tag.font_size,
          font_type: tag.font_type,
          font_color : tag.font_color
        };
    
        if (!transformedData[pageNumber]) {
          transformedData[pageNumber] = [];
        }
    
        transformedData[pageNumber].push(transformedTag);
      });
    
      return transformedData;
    }

    const getfile = async(id , file_name) => {
      try{
        const response = await axios.get(process.env.REACT_APP_DEV_URL + "api/pdf/" + id, {
         responseType: 'arraybuffer',
        });
        if(response.status === 200){
          const blob = new Blob([response.data], { type: 'pdf' });
          updatePdfData("pdf_file", new File([response.data], file_name, {type: 'pdf'}));
          dispatch(setfetched(true));
        }
        
      }catch(error){
        console.log(error);

      }finally{
        setLoading(false);
      }
    }
    const getPdfDetails = async() => {
      try{
        const res = await axios.get(process.env.REACT_APP_DEV_URL + "api/uploaded-pdf-details/" + pdfId, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          }
        });
        const data = res.data[0];
        if(res.status === 200){
          const tagData = data.tag_data;
          if (tagData) {
            const transformedData = transformTagData(tagData);
            sessionStorage.setItem('pdfTextBoxData', JSON.stringify(transformedData));
          }
        }
        
        const file_name = data.pdf_path;
        getfile(data.id , file_name);
  
      }catch(error){
        console.log(error);
      }
    };

   useEffect(() => {
    if (currentStepPdf && pdfId && !pdfDetails.current) {
        pdfDetails.current = true;
        getPdfDetails();
    }
   },[pdfId ,currentStepPdf]);

   useEffect(() => {
    // clearing session storage 
    sessionStorage.clear();

    // setting the Tag1 value in the session storage 
    sessionStorage.setItem('pdfTextBoxData', JSON.stringify({ 1: [{ pageNumber: 1, x: 0, y: 0, text: "Tag 1", font_type: "Arial", font_color: "#000000", font_size: "10" }] }));
   },[]);

    const steps = [
        {
          title: "Upload PDF",
          content: (
            <UploadPDF
              next={next}
              PdfData={PdfData}
              updatePdfData ={updatePdfData}
              onPageChange={handleScroll}
              handleNumberofPages={handleNumberofPages}
            />
            
            
          ),
        },
        {
          title: "Upload CSV File",
          content: (
            <UploadCSV
              PdfData={PdfData}
              updatePdfData ={updatePdfData}
              next={next}
              csvrowCount={csvrowCount}
              setCsvrowCount={setCsvrowCount}
            />
            
          ),
        }
      ];
    
    if (isPaymentExpected) {
        steps.push({
          title: "Make Payment",
          content: <PDFPayment rowCount={csvrowCount} pdfId={pdfId} />,
        });
    }
 
  const items = steps?.map((item) => ({ key: item.title, title: item.title }));
      
  return (
    <>
    <Spin spinning={loading}>
      <div className="stepper-container">
        <Steps current={currentStepsIndex} items={items} className="stepper" />
      </div>
      <div style={{ marginTop: "20px" }}>{steps[currentStepsIndex]?.content}</div>
      <div className="button-container">

        {currentStepsIndex !== 0 && (
          <Button
            className="prev-button"
            style={{ marginLeft: "10px" }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}

        {isPaymentExpected && currentStepsIndex === steps.length - 1 && <PDFPaymentButton pdfId={pdfId} />}
      </div>
      {isPaymentExpected && currentStepsIndex === 2 && <div style={{ height: "350px" }} />}
       </Spin>
   </>
  )
})

export default Stepper
                         