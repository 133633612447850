// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    margin-top: 50px;
    background-color: var(--primary-color);
    color:white;
    padding: 16px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Footer/footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sCAAsC;IACtC,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".footer{\n    margin-top: 50px;\n    background-color: var(--primary-color);\n    color:white;\n    padding: 16px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
