import React from 'react'
import Header from '../../layout/Header/Header';
import TaskResultPDF from "../TaskResultPDF";
import PdfRowlistTable from './PdfRowlistTable';

const RowListMain = () => {
  return (
    <div>
      <Header/>
      <TaskResultPDF />
      <PdfRowlistTable/>
    </div>
  )
}

export default RowListMain;
