import React, { useState, useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, message, Upload, notification } from "antd";
import demoExcel from "../../../assets/excel/DemoNamesFile.csv";
import { setCurrentStep } from "../../../store/slices/authSlice";
import axios from "axios";
import Papa from "papaparse";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/slices/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;

const UploadExcel = ({
  rowCount,
  setRowCount,
  setVideoId,
  updateVideoData,
  videoData,
  setVideoData,
  next
}) => {
  const [uploadedFileName, setUploadedFileName] = useState(""); // State variable to store the uploaded file name
  const props = {
    name: "file",
    multiple: true,

    onChange(info) { },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (videoData.csv_file) {
      setUploadedFileName(videoData.csv_file.name);
    }
  }, []);

  const dispatch = useDispatch();
  const userEmail = localStorage.getItem("userEmail");
  const emailList = process.env.REACT_APP_EMAIL_LIST.split(",");
  const isPaymentExpected = !emailList.includes(userEmail);
  const navigate = useNavigate();
  const handleFileUpload = (event) => {
    const file = event.fileList[0].originFileObj;

    // Check if the uploaded file has a CSV extension
    if (!file.name.toLowerCase().endsWith(".csv")) {
      message.error("Please upload a CSV file.");
      return;
    }

    // Set the uploaded file name
    setUploadedFileName(file.name);

    updateVideoData("csv_file", event.fileList[0].originFileObj)
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      // Parse the CSV data using Papa Parse
      const parsedData = Papa.parse(csvData);
      const rowCount = parsedData.data.length ; 

      
      if(parsedData.data[rowCount - 1][0] !== ''){
        setRowCount(rowCount-1);
      }else{
        setRowCount(rowCount-2);
      }
    };

    reader.readAsText(file);
  };

  const handleSubmit = () => {
    if (!videoData.csv_file) {
      notification.error({ message: "please upload csv" });
      return false;
    }
    dispatch(setLoading(true));

    const form_data = new FormData();
    form_data.append("csv_file", videoData.csv_file);
    form_data.append("video_file", videoData.video_file);
    form_data.append("x", videoData.x);
    form_data.append("y", videoData.y);
    form_data.append("font_type", videoData.font_type);
    form_data.append("font_color", videoData.font_color);
    form_data.append("start_clip", videoData.start_clip);
    form_data.append("end_clip", videoData.end_clip);
    form_data.append("font_size", videoData.font_size);
    form_data.append("width_box", videoData.width_box);
    form_data.append("height_box", videoData.height_box);
    // form_data.append("video_id", video_id_redux);
    axios
      .post(
        process.env.REACT_APP_DEV_URL + "api/upload-files-v2/",
        form_data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setVideoId(res.data.video_id)
        dispatch(setLoading(false));
        if(isPaymentExpected){
          next()
        }else{
          // window.location.reload(true);
          navigate("/edit-video");
          setVideoData({
            csv_file: null,
            video_file: null,
            x: 0,
            y: 0,
            font_type: "Arial",
            font_color: "#000000",
            start_clip: 0,
            end_clip: 0,
            font_size: 10,
            width_box: 0,
            height_box: 0,
          });
          dispatch(setCurrentStep(1));
        }
        
      })
      .catch((err) => {
        notification.error({ message: err?.response?.data?.detail });
        dispatch(setLoading(false));
      });
  };

  return (
    <div>
      <Button
        href={demoExcel}
        className="prev-button"
        download="DemoNamesFile.csv"
      >
        Download Demo CSV Sheet
      </Button>
      <Dragger
        {...props}
        style={{ maxWidth: "650px", marginTop: "20px" }}
        previewFile={true}
        maxCount={1}
        accept=".xlsx,.xlsm,.xls,.csv"
        onChange={handleFileUpload}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>

        {!uploadedFileName && (
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        )}
        {uploadedFileName && ( // Display the uploaded file name if it exists
          <p className="ant-upload-text">Uploaded file: {uploadedFileName}</p>
        )}
      </Dragger>
      <div
        style={{
          display: "flex",
          columnGap: "10px",
          alignItems: "center",
          gap: "10px",
          marginTop: "5px",
        }}
      >
        Guest count: {rowCount}
        <Button className="next-button" onClick={handleSubmit}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default UploadExcel;
