import React from "react";
import UploadVideo from "./UploadVideo";
import Header from "../layout/Header/Header";
import "./index.css";
import Footer from "../layout/Footer/Footer";
import { useSelector } from "react-redux";
import { Spin } from 'antd';

const UploadVideoIndex = () => {
  const { loading } = useSelector((state) => state.auth);

  return (
    <>
      <Header />
      <Spin spinning={loading}>
        <UploadVideo />
      </Spin>
      <Footer />
    </>
  );
};

export default UploadVideoIndex;
