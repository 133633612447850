// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GuestlistTable.css */

/* Guestlist container */
.guestlist-container {
    position: relative; /* Relative positioning for the container */
  }
  
  /* Blur overlay */
  .blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
    -webkit-backdrop-filter: blur(8px); /* Adjust the blur amount as needed */
    backdrop-filter: blur(8px); /* For Safari on iOS */
    z-index: 1000; /* Ensure it's on top of other content */
    display: flex;
    align-items: center;
    justify-content: center;
  }`, "",{"version":3,"sources":["webpack://./src/components/task-status-pdf/pdf-details/PdfRowlistTable.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB,wBAAwB;AACxB;IACI,kBAAkB,EAAE,2CAA2C;EACjE;;EAEA,iBAAiB;EACjB;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,iCAAiC;IACvE,kCAAkC,EAAE,qCAAqC;IACzE,0BAA0B,EAAE,sBAAsB;IAClD,aAAa,EAAE,wCAAwC;IACvD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB","sourcesContent":["/* GuestlistTable.css */\n\n/* Guestlist container */\n.guestlist-container {\n    position: relative; /* Relative positioning for the container */\n  }\n  \n  /* Blur overlay */\n  .blur-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */\n    -webkit-backdrop-filter: blur(8px); /* Adjust the blur amount as needed */\n    backdrop-filter: blur(8px); /* For Safari on iOS */\n    z-index: 1000; /* Ensure it's on top of other content */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
