import React from 'react'
import Header from '../layout/Header/Header';
import TaskResultPDF from './TaskResultPDF';
import StatusCardPDF from '../upload-pdf/StatusCardPDF';
import { Row } from 'antd';


const MainDashboardPDFStatus = () => {
  return (
    <>
    <Header />
      <TaskResultPDF />
      <div className="upload-main-container">
        Render PDF Status
        <Row gutter={[20, 20]} className="status-card">
          <StatusCardPDF />
        </Row>
        </div>
    </>
  )
}

export default MainDashboardPDFStatus;
