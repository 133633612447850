import React from 'react'
import Header from '../layout/Header/Header'
import { useSelector } from 'react-redux';
import UploadPDF from './UploadPDF';
import Footer from '../layout/Footer/Footer';
import { Spin } from 'antd';
import "./index.css"

const UploadPDFIndex = () => {
    const { loading } = useSelector((state) => state.auth);

  return (
    <div className='main-container'>
      <Header/>
      <Spin spinning={loading}>
        <UploadPDF />
      </Spin>
      <Footer />
    </div>
  )
}

export default UploadPDFIndex
