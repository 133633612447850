import React from 'react'
import StatusCardPDF from './StatusCardPDF';
import { Typography, Row } from 'antd';

const RenderPDFStatus = () => {
  return (
    <div className="upload-main-container">
      <Typography.Text className="upload-header-text">
        Render PDF status
      </Typography.Text>
      <Row style={{ marginTop: "20px" }} gutter={[20, 20]}>
        <StatusCardPDF />
      </Row>
    </div>
   
  )
}

export default RenderPDFStatus
