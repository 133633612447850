import React , {useEffect, useState}from 'react'
import moment from "moment";
import { Typography, Col, List, Image, Button, notification, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import noFound from "../../assets/icons/no_found.svg";
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';

const StatusCardPDF = () => {
    const [success, setsuccess] = useState([]);
    const [in_process, setinprocess] = useState([]);
    const [in_queue, setinqueue] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState("");

    const showDeleteModal = (id, status) => {
      setSelectedId(id);
      setSelectedStatus(status);
      setIsModalOpen(true);
    };
    
  
    const handleOk = async () => {
      if (selectedId && selectedStatus) {
        if (selectedStatus === "completed") {
          await handleDeleteCompleted(selectedId);
        } else if (selectedStatus === "unprocessed") {
          await handleDeleteInQueue(selectedId);
        }
      }
      setIsModalOpen(false);
      setSelectedId(null);
      setSelectedStatus("");
    };
    
    const handleCancel = () => {
      setIsModalOpen(false);
      setSelectedId(null);
      setSelectedStatus("");
    };
    

    const navigate = useNavigate();

    const navigateToDetails = (id) => {
       console.log(id);
        const url = "/task-status-pdf/details/" + id;
        window.open(url, "_blank");
      };

    useEffect(() => {
        axios
        .get(process.env.REACT_APP_DEV_URL + "api/pdfs", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
          params: {
            status: "completed"
          }
        })
        .then((res) => {
          setsuccess(res.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            localStorage.clear();
            navigate("/login"); 
            window.location.reload();
            return; 
          }
          notification.error({ message: err });
        });
  
      axios
        .get(process.env.REACT_APP_DEV_URL + "api/pdfs", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
          params: {
            status: "inprogress",
          }
        })
        .then((res) => {
          setinprocess(res.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            localStorage.clear();
            navigate("/login");
            window.location.reload();
            return; 
          }
          notification.error({ message: err });
        });
  
      axios
        .get(process.env.REACT_APP_DEV_URL + "api/pdfs", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
          params: {
            status: "unprocessed",
          }
        })
        .then((res) => {
          setinqueue(res.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            localStorage.clear();
            navigate("/login");
            window.location.reload();
            return; 
          }
          notification.error({ message: err });
        });
    },[]);

    const handleDeleteCompleted= async(id)=>{
     try{
      const res = await axios.delete(process.env.REACT_APP_DEV_URL + "api/delete_pdf/"+ id +"?status=completed", {
        headers:{
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          }
        }
      );
      setsuccess(success.filter((item) => item.id !== id));
      if(res.status == 200){
        notification.success({ message: "PDF deleted successfully!" });
      }
     }catch(error){
      console.log(error);
     }
      
    };

    const handleDeleteInQueue= async(id)=>{
      try{
        const res = await axios.delete(process.env.REACT_APP_DEV_URL + "api/delete_pdf/"+ id +"?status=unprocessed", {
          headers:{
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            }
          }
        );
        setinqueue(in_queue.filter((item) => item.pdf_id !== id));
        if(res.status == 200){
          notification.success({ message: "PDF deleted successfully!" });
        }
       }catch(error){
        console.log(error);
       }
    };

    const statusCardDetails = [
        {
          title: "Inqueue",
          backColor: "var(--inqueue-color)",
          textColor: "#FBC450",
          list: in_queue,
        },
        {
          title: "In Progress",
          backColor: "var(--progress-color)",
          textColor: "#4BAAFF",
          list: in_process,
        },
        {
          title: "Completed",
          backColor: "var(--success-color)",
          textColor: "#90DA69",
          list: success,
        },
      ];
  return (
    <>
    {statusCardDetails?.map((card, index) => (
      <Col lg={8} xs={24} key={index}>
        {card.title === "Completed" && (
          <div className="status-container">
            <div
              className="status-header"
              style={{ background: card.backColor, color: card.textColor }}
            >
              {card.title}
            </div>
            <div className="status-body">
              {card.list.length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={success}
                  renderItem={(item, index) => (
                    <List.Item key={index} className="status-list">
                      <List.Item.Meta
                        title={
                          <Typography.Text>{item.main_name}</Typography.Text>
                        }
                        description={
                          <>
                            <div className="discWrapper">
                              <div>
                                Time:{" "}
                                {moment
                                  .utc(item.time, "HH:mm:ss")
                                  .local()
                                  .format("hh:mm A")}
                              </div>
                              <div>
                                Date:{" "}
                                {item.date}
                              </div>
                              <Button
                                className="view-details-btn"
                                onClick={() => navigateToDetails(item.id)}
                                style={{
                                  marginRight: "135px",
                                  marginTop: "5px",
                                  width: "112px",
                                }}
                              >
                                View details
                              </Button>
                            </div>
                          </>
                        }
                      />
                        <div className='status-delete-btn' onClick={() => showDeleteModal(item.id, "completed")}>
                          <DeleteOutlined className='delete-icon'/>
                        </div>
                    </List.Item>
                  )}
                />
              ) : (
                <div className="no-found-container">
                  <Image src={noFound} alt="not found" preview={false} />
                  <div>Opps !! There are no files here !</div>
                </div>
              )}
            </div>
          </div>
        )}

        {card.title === "In Progress" && (
          <div className="status-container">
            <div
              className="status-header"
              style={{ background: card.backColor, color: card.textColor }}
            >
              {card.title}
            </div>
            <div className="status-body">
              {card.list.length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={in_process}
                  renderItem={(item, index) => (
                    <List.Item key={index} className="status-list">
                      <List.Item.Meta
                        title={
                          <Typography.Text>{item.pdf_name}</Typography.Text>
                        }
                        description={
                          <>
                            <div style={{ float: "left" }}>
                              <div>
                                Upload Time:{" "}
                                {moment
                                  .utc(item.upload_time, "HH:mm:ss")
                                  .local()
                                  .format("hh:mm A")}
                              </div>
                              <div>
                                Date:{" "}
                                {item.date}
                              </div>
                            </div>
                          </>
                        }
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <div className="no-found-container">
                  <Image src={noFound} alt="not found" preview={false} />
                  <div>Opps !! There are no files here !</div>
                </div>
              )}
            </div>
          </div>
        )}

        {card.title === "Inqueue" && (
          <div className="status-container">
            <div
              className="status-header"
              style={{ background: card.backColor, color: card.textColor }}
            >
              {card.title}
            </div>
            <div className="status-body">
              {card.list.length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={in_queue}
                  renderItem={(item, index) => (
                    <List.Item key={index} className="status-list">
                      <List.Item.Meta
                        title={
                          <Typography.Text>{item.pdf_name}</Typography.Text>
                        }
                        description={
                          <>
                            <div style={{ float: "left" }}>
                              <div>
                                Upload Time:{" "}
                                {moment
                                  .utc(item.upload_time, "HH:mm:ss")
                                  .local()
                                  .format("hh:mm A")}
                              </div>
                              <div>
                                Date:{" "}
                                {item.date}
                              </div>
                            </div>
                          </>
                        }
                      />
                        <div className='status-delete-btn' onClick={() => showDeleteModal(item.pdf_id, "unprocessed")}>
                          <DeleteOutlined className='delete-icon'/>
                        </div>
                    </List.Item>
                  )}
                  
                />
              ) : (
                <div className="no-found-container">
                  <Image src={noFound} alt="not found" preview={false} />
                  <div>Opps !! There are no files here !</div>
                </div>
              )}
            </div>
          </div>
        )}
      </Col>
    ))}

    <Modal title="Confirm Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <p>Are you sure you want to delete this PDF?</p>
    </Modal>

  </>
  )
}

export default StatusCardPDF
