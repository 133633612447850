import React from "react";
import Header from "../layout/Header/Header";
import TaskResult from "./TaskResult";
import { Row } from "antd";
import StatusCard from "../upload-video/StatusCard";

const DashboardMain = () => {
  return (
    <>
      <Header />
      <TaskResult />
      <div className="">
      <div className="upload-main-container">
        Render Video status
        <Row gutter={[20, 20]} className="status-card">
          <StatusCard />
        </Row>
        </div>
      </div>
    </>
  );
};

export default DashboardMain;
