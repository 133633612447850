import React from "react";

const PDFPayment = ({rowCount, pdfId}) => {
  const amount = process.env.REACT_APP_PDF_Payment_amount;
  const gst = process.env.REACT_APP_Gst_amount;
  const paymentDetails = [
    {
      title: "Quantity:",
      desc: rowCount+" Rows",
    },
    {
      title: "Amount:",
      desc: rowCount * amount+" ₹",
    },
    {
      title: "GST (18%):",
      desc: ((rowCount * amount) * gst).toFixed(2) + " ₹" ,
    },
  ];
  return (
    <div className="payment-container">
      <div className="payment-header-footer payment-flex">
        <div>Service</div>
        <div>PDF Personalisation</div>
      </div>
      <hr className="payment-divider" />
      <div className="payment-body">
        {paymentDetails?.map((payment, index) => (
          <div className="payment-flex" key={index}>
            <div>{payment.title}</div>
            <div>{payment.desc}</div>
          </div>
        ))}
      </div>
      <hr className="payment-divider" />
      <div className="payment-header-footer payment-flex">
        <div>Amount to pay</div>
        <div>{rowCount * amount + ((rowCount * amount) * gst)} ₹</div>
      </div>
    </div>
  );
};

export default PDFPayment;
