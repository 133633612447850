import React , {useState, useEffect,useRef} from 'react'
import { Button, message, Upload, notification } from "antd";
import Papa from "papaparse";
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setPdfId, setLoading,setCurrentStepPdf } from '../../../store/slices/authSlice';
import axios from 'axios';
import democsv from "../../../assets/excel/DemoCSVforPdf.csv"
import { useNavigate } from 'react-router-dom';

const { Dragger } = Upload;
const UploadCSV = ({PdfData, updatePdfData, next,csvrowCount,setCsvrowCount}) => {

  const [uploadedFileName, setUploadedFileName] = useState("");
  const [formatedJsonData , setFormatedJsonData] = useState([]);

  const errorCounterref = useRef(false);

  const dispatch = useDispatch();
  const userEmail = localStorage.getItem("userEmail");
  const emailList = process.env.REACT_APP_EMAIL_LIST.split(",");
  const isPaymentExpected = !emailList.includes(userEmail);

  const navigate = useNavigate();

  const props = {
    name: "file",
    multiple: true,

    onChange(info) { },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };


  
  const handleFileUpload = (event) => {
    const file = event.fileList[0].originFileObj;
    errorCounterref.current = false;
    if (!file.name.toLowerCase().endsWith(".csv")) {
      message.error("Please upload a CSV file.");
      return;
    }

    setUploadedFileName(file.name);

    updatePdfData("csv_file", event.fileList[0].originFileObj)
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      const parsedData = Papa.parse(csvData, { header: true });

      if (!parsedData.data || !parsedData.meta.fields) {
        message.error({message : "Failed to parse CSV file. Please check the file format."});
        handleFileUploadError();
        return;
      }

      const validationErrors = validateCSV(parsedData);

      if (validationErrors.length > 0 && !errorCounterref.current) {
        errorCounterref.current = true;

        const uniqueErrors = new Set(validationErrors);

        uniqueErrors.forEach((error) => {
          console.log(error);
          notification.error({ message: error });
        });
        handleFileUploadError();
        return;
      }

      const rowCountValue = parsedData.data.length; 
      if(parsedData.data[rowCountValue-1]["Tag 1"] !== undefined){
        setCsvrowCount(rowCountValue);
      }else{
        setCsvrowCount(rowCountValue-1);
      }
      
    };

    reader.readAsText(file);
  };

  const handleFileUploadError = () => {
    updatePdfData("csv_file", null);
    setUploadedFileName("");
    setCsvrowCount(0);
  };

  const validateCSV = (parsedData) => {
    const errors = new Set();
    const { data, meta } = parsedData;
  
    const trimmedFields = meta.fields.map(field => field.trim());
  
    // Check for the presence of "Pages" column
    if (!trimmedFields.includes("Pages")) {
      errors.add("The CSV must contain a 'Pages' column.");
    } else {
      data.forEach((row, index) => {
        const pagesValue = row.Pages?.trim();
        if (pagesValue && !/^(\d+,)*\d+$/.test(pagesValue)) {
          errors.add(`Row ${index + 2}: 'Pages' column must contain comma-separated numeric values or be empty.`);
        }
      });
    }
  
    // Check for the presence of "Tag 1" column
    if (!trimmedFields.includes("Tag 1")) {
      errors.add("The CSV must contain a 'Tag 1' column.");
    } else {
      // Validate "Tag 1" values
      data.forEach((row, index) => {
        // Clean the keys to remove leading/trailing spaces
        const cleanedRow = Object.keys(row).reduce((acc, key) => {
          const cleanedKey = key.trim();
          acc[cleanedKey] = row[key];
          return acc;
        }, {});
      
        // Check if the row is empty
        const pagesValue = cleanedRow["Pages"]?.trim();
        const tag1Value = cleanedRow["Tag 1"]?.trim();
      
        if (!pagesValue && !tag1Value) {
          // Skip processing this row if both "Pages" and "Tag 1" are empty
          return;
        }
      
        if (!tag1Value) {
          errors.add(`Row ${index + 2}: 'Tag 1' value is not specified.`);
        }
      
        // Additional validation for "Pages" if needed
        if (pagesValue && !/^(\d+,)*\d+$/.test(pagesValue)) {
          errors.add(`Row ${index + 2}: 'Pages' column must contain comma-separated numeric values or be empty.`);
        }
      });
    }
  
    // Validate other column headers
    trimmedFields.forEach((field) => {
      if (field !== "Pages" && field !== "Tag 1" && !/^Tag \d+$/.test(field)) {
        errors.add(`Column header '${field}' is invalid. It should be in the format 'Tag 1', 'Tag 2', etc.`);
      }
    });
  
    // Check for empty column headers
    if (trimmedFields.some((field) => field.trim() === "")) {
      errors.add("Columns should not be empty.");
    }
  
    return Array.from(errors);
  };
  
  const handleCreateJSON = () => {
      const jsonData = sessionStorage.getItem("pdfTextBoxData");
      
      if (!jsonData) {
          console.log("No data found in sessionStorage.");
          return;
      }

      const parsedData = JSON.parse(jsonData);
      let formattedData = [];

      
      Object.keys(parsedData).forEach(page => {
          parsedData[page].forEach(item => {
              formattedData.push({
                  page_number: item.pageNumber,
                  x: item.x,
                  y: item.y,
                  text: item.text,
                  font_color :item.font_color,
                  font_size :item.font_size,
                  font_type :item.font_type

              });
          });
      });

      console.log(formattedData);
      setFormatedJsonData(JSON.stringify(formattedData));
  };

  useEffect(() => {
      handleCreateJSON();
  }, []);

  const handleSubmit = async() => {
    if (!PdfData.csv_file) {
      notification.error({ message: "please upload csv" });
      return false;
    }
    dispatch(setLoading(true));
    
    const form_data = new FormData();
    form_data.append("csv_file", PdfData.csv_file);
    form_data.append("pdf_file", PdfData.pdf_file);
    form_data.append("tag_data",formatedJsonData);

    try {
      const res = await axios.post(
        process.env.REACT_APP_DEV_URL + "api/upload-pdf-file/",
        form_data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      );

      if (res.status === 200) {
        dispatch(setPdfId(res.data.pdf_id));
      }
      
    } catch (error) {
      console.log("Error occured while submiting" , error)
      notification.error({message : "Error occured while submiting"});
    }finally{
      dispatch(setLoading(false));
    }

    if(isPaymentExpected){
      next();
    }
    else{
      navigate("/edit-pdf");
      PdfData.csv_file = null;
      PdfData.pdf_file = null;
      sessionStorage.clear();
      dispatch(setCurrentStepPdf(0));

    }
  };

  useEffect(() => {
    if (PdfData.csv_file) {
      setUploadedFileName(PdfData.csv_file.name);
    }
  }, []);



  return (
    <div>
      <Button
        href={democsv} 
        className="prev-button"
        download="DemoNamesFile.csv"
      >
        Download Demo CSV Sheet
      </Button>
      <Dragger
        {...props}
        style={{ maxWidth: "650px", marginTop: "20px" }}
        previewFile={true}
        maxCount={1}
        accept=".xlsx,.xlsm,.xls,.csv"
        onChange={handleFileUpload}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>

        {!uploadedFileName && (
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        )}
        {uploadedFileName && ( // Display the uploaded file name if it exists
          <p className="ant-upload-text">Uploaded file: {uploadedFileName}</p>
        )}
      </Dragger>
      <div
        style={{
          display: "flex",
          columnGap: "10px",
          alignItems: "center",
          gap: "10px",
          marginTop: "5px",
        }}
      >
        Row count: {csvrowCount}
        <Button className="next-button" onClick={handleSubmit}>
          Next
        </Button>
      </div>
    </div>
  )
}

export default UploadCSV
