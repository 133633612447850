import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, notification, Upload } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { setColor, setFont, setFontSize } from "../../../../store/slices/textStylesSlice";
import PDFForm from "./PDFForm";
import "./upload-pdf.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const { Dragger } = Upload;

const UploadPDF = ({ next, PdfData, updatePdfData,onPageChange , handleNumberofPages }) => {
  const [pdfblob, setPdfblob] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentVisiblePage, setCurrentVisiblePage] = useState(1);
  const [pageDimensions, setPageDimensions] = useState({});
  const [textBoxData, setTextBoxData] = useState({});
  const [tagCount, setTagCount] = useState(1);
  const [mouseClickTagData , setMouseClickTagData]= useState({});
  const [deletedTags, setDeletedTags] = useState([]);

  const scrollContainerRef = useRef(null);
  const { fontSize, font, color } = useSelector((state) => state.textStyle);

  const dispatch = useDispatch();

  const props = {
    name: "file",
    accept: ".pdf",
    maxCount: 1,
    onChange(info) {
      const file = info.fileList[0]?.originFileObj;
      if (file) {
        if (file.type !== "application/pdf") {
          notification.error({
            message: "Invalid File Type",
            description: "Only PDF files are allowed.",
          });
          return;
        }

        setPdfblob(URL.createObjectURL(file));
        updatePdfData("pdf_file", file);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (PdfData?.pdf_file instanceof File) {
      const url = URL.createObjectURL(PdfData.pdf_file);
      setPdfblob(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    } else if (PdfData?.pdf_file instanceof ArrayBuffer) {
      const blob = new Blob([PdfData.pdf_file], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setPdfblob(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      console.error("Invalid PDF file format:", PdfData?.pdf_file);
    }
  }, [PdfData]);

  useEffect(() => {
    // initaly storing the tag1 in session storage
    const storedData = sessionStorage.getItem("pdfTextBoxData") || JSON.stringify({ 1: [{ pageNumber: 1, x: 0, y: 0, text: "Tag 1", font_type: "Arial", font_color: "#000000", font_size: "10" }] });
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setTextBoxData(parsedData);
      let highestTagNumber = 0;
      Object.values(parsedData)?.forEach(tags => {
        tags?.forEach(tag => {
          const tagNumber = parseInt(tag.text.replace("Tag ", ""), 10);
          if (tagNumber > highestTagNumber) {
            highestTagNumber = tagNumber;
          }
        });
      });
      
      setTagCount(highestTagNumber);
      handleSetTextDataToInitialState(); 
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.clear();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const onDocumentLoadSuccessNumberOfPages = ({ numPages }) => {
    setNumPages(numPages);
    handleNumberofPages({numPages});
  };

  const onDocumentLoadError = (error) => {
    console.error("Error loading PDF:", error);
  };

  const handleScroll = useCallback(() => {
    if (!scrollContainerRef.current) return;
    const container = scrollContainerRef.current;
    const pages = container.getElementsByClassName("pdfPage");
    let maxVisibleHeight = 0;
    let mostVisiblePage = 1;

    Array.from(pages).forEach((page, index) => {
      const rect = page.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      const visibleTop = Math.max(rect.top, containerRect.top);
      const visibleBottom = Math.min(rect.bottom, containerRect.bottom);
      const visibleHeight = Math.max(0, visibleBottom - visibleTop);

      if (visibleHeight > maxVisibleHeight) {
        maxVisibleHeight = visibleHeight;
        mostVisiblePage = index + 1;
      }
    });
    setCurrentVisiblePage(mostVisiblePage);
    if (onPageChange) {
      onPageChange(mostVisiblePage);
    }
  }, [onPageChange]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll,numPages]);

  const handlePageLoadSuccess = (page, pageNumber) => {
    const { width, height } = page;
    setPageDimensions((prev) => ({
      ...prev,
      [pageNumber]: { width, height },
    }));
  };
  
  const handleAddTageincurrentPage = () => {
    const currentStyles = {
      font_size: "10",
      font_type: "Arial", 
      font_color: "#000000"
    };
    
    setTextBoxData((prevData) => {
      let newTag;
      if (deletedTags.length > 0) {
        // Reuse the first deleted tag
        newTag = deletedTags[0];
        // Remove the reused tag from the deletedTags list
        setDeletedTags((prevDeletedTags) => prevDeletedTags.slice(1));
      } else {
        // Calculate the highest tag number currently in use
        let highestTagNumber = 0;
        Object.values(prevData)?.forEach(tags => {
          tags?.forEach(tag => {
            const tagNumber = parseInt(tag.text.replace("Tag ", ""), 10);
            if (tagNumber > highestTagNumber) {
              highestTagNumber = tagNumber;
            }
          });
        });
  
        // Determine the new tag number
        const newTagNumber = highestTagNumber + 1;
        newTag = `Tag ${newTagNumber}`;
  
        // Update the tag count
        setTagCount(newTagNumber);
      }
  
      const newTagData = {
        pageNumber: currentVisiblePage,
        x: 0,
        y: 0,
        text: newTag,
        ...currentStyles
      };
  
      // Create a deep copy of the previous data to ensure proper state updates
      const newData = {
        ...prevData,
        [currentVisiblePage]: [
          ...(prevData[currentVisiblePage] || []),
          newTagData
        ]
      };
  
      // Immediately update session storage
      sessionStorage.setItem("pdfTextBoxData", JSON.stringify(newData));
      
      return newData;
    });
  };
  const handleSetTextDataToInitialState = () => {
    dispatch(setColor("#000000"));
    dispatch(setFontSize("10"));
    dispatch(setFont("Arial"));
  };


  const handleRemoveInputTag = (pageNumber, index) => {
    setTextBoxData((prevData) => {
      const updatedData = { ...prevData };
      const pageTags = updatedData[pageNumber] || [];
  
      if (index >= pageTags.length) {
        console.error(`Index ${index} out of bounds for page ${pageNumber}`);
        return updatedData;
      }
  
      const deletedTag = pageTags[index].text;
  
      // Ensure we don't remove "Tag 1" on the first page
      if (pageNumber === 1 && pageTags[index]?.text === "Tag 1") {
        return updatedData;
      }
  
      // Remove the specific tag at the given index
      pageTags.splice(index, 1);
      setDeletedTags((prevDeletedTags) => [deletedTag, ...prevDeletedTags]);
  
      return { ...updatedData, [pageNumber]: pageTags };
    });
  };

 
  const updateTextBoxData = (pageNumber, index, newData) => {
    setTextBoxData((prevData) => {
      const updatedPageData = [...(prevData[pageNumber] || [])];
      updatedPageData[index] = { ...updatedPageData[index], ...newData };
      return { ...prevData, [pageNumber]: updatedPageData };
    });
  };

  useEffect(() => {
    if (Object.keys(textBoxData).length > 0) {
      sessionStorage.setItem("pdfTextBoxData", JSON.stringify(textBoxData));
    }
  }, [textBoxData]);

  const handePageChnageFn = (pageNumber) => {
    console.log("page change", pageNumber);
  };

  useEffect(() => {
    setTextBoxData((prevData) => {
      const updatedData = { ...prevData };
      const { pageNumber, index } = mouseClickTagData;
      updatedData[pageNumber] = updatedData[pageNumber]?.map((tag, i) => {
        if (i === index) {
          return {
            ...tag,
            font_type: font,
            font_color: color,
            font_size: fontSize,  
          };
        }
        return tag;
      });
      return updatedData;
    });
}, [font, color, fontSize]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const tagHandle = document.querySelector(`.tag-handle-${mouseClickTagData.pageNumber}-${mouseClickTagData.index}`);
      if (tagHandle && !tagHandle.contains(event.target)) {
        setMouseClickTagData({});
      }
    };
  }, [mouseClickTagData]);

  const handleTagSelection = (tagData) => {
    if (tagData) {
      const colorValue = tagData.font_color      
      const fontSizeValue = tagData.font_size  
      const fontValue = tagData.font_type 
  
      dispatch(setColor(colorValue));
      dispatch(setFontSize(fontSizeValue));
      dispatch(setFont(fontValue));
    }
  };

  return (
    <div >
      {pdfblob === null ? (
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single PDF upload.</p>
        </Dragger>
      ) : (
        <div className="centered-container">
          <div className="pdfContainer" onScroll={(e)=>onPageChange()}>
          <div className="pdfHeader">
            <div className="pdfHeaderContent">Number of Pages {currentVisiblePage}/{numPages}</div>
              <Button size="small" type="primary" onClick={handleAddTageincurrentPage}>
                Add Tag
              </Button>
            </div>
            <Document
              file={pdfblob}
              onLoadSuccess={onDocumentLoadSuccessNumberOfPages}
              onError={onDocumentLoadError}
            >
              <div ref={scrollContainerRef} className="pdfPagesContainer">
                {numPages &&
                  Array.from({ length: numPages }, (_, index) => index + 1).map((pageNumber) => (
                    <div key={pageNumber} className="pdfPage">
                      <Page
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        key={pageNumber}
                        onLoadSuccess={(page) => handlePageLoadSuccess(page, pageNumber)}
                      />
                      {textBoxData[pageNumber]?.map((tagData, index) => (
                        <Draggable
                          key={`${pageNumber}-${index}`}
                          axis="both"
                          handle=".handle"
                          position={{ x: tagData.x, y: tagData.y }}
                          scale={1}
                          bounds={{
                            left: 0,
                            top: 0,
                            right: (pageDimensions[pageNumber]?.width || 0) - 
                            ((tagData.text?.length || 0) * (parseInt(tagData.font_size) * 0.6) + 20),
                            bottom: (pageDimensions[pageNumber]?.height || 0) - 
                             (parseInt(tagData.font_size) + 12),
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            setMouseClickTagData({ pageNumber, index, e})
                            handleTagSelection(tagData)
                          }}

                          onDrag={(e, data) => updateTextBoxData(pageNumber, index, { x: data.x, y: data.y })}
                        >
                          <div className={`handle tag-handle-${pageNumber}-${index} ${tagData.text === mouseClickTagData?.e?.target?.value ? "active" : ""}`}>
                            <input
                              className={`tag-input `}
                              type="text"
                              value={tagData.text}
                              readOnly
                              style={{
                                // width: `${tagData?.width || tagData?.text?.length * 10}px`,
                                // height: `${tagData?.height || 20}px`,
                                width: `calc(${tagData?.text?.length}ch + 10px)`,
                                height: `auto`,
                                fontSize: `${tagData?.font_size}px`,
                                fontFamily: tagData?.font_type,
                                color: tagData?.font_color,
                                padding: "5px",
                                border: "1px solid #ccc",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            />
                            <div className="delete-icon-preview" 
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event bubbling
                              if (tagData.text !== "Tag 1") {
                                handleRemoveInputTag(pageNumber, index); // Pass pageNumber and index
                              }
                            }} 
                            style={{ cursor: tagData?.text !== "Tag 1" ?"pointer": "not-allowed" }}>
                              <DeleteOutlined />
                            </div>
                          </div>
                        </Draggable>
                      ))}
                    </div>
                  ))}
              </div>
            </Document>
          </div>
          <PDFForm next={next} PdfData={PdfData}
            textBoxData={textBoxData} mouseClickTagData={mouseClickTagData}/>
        </div>
      )}
    </div>
  );
};

export default UploadPDF;
