import React, {useState,useEffect} from "react";
import "./pdf-form.css";
import {
  Button,
  Space,
  Form,
  ColorPicker,
  Input,
  notification,
} from "antd";
import {
  direction
} from "./PDFUtlity";
import FontDropdown from './FontDropdown';
import GlobalFonts from './font';
import { useDispatch, useSelector } from "react-redux";
import { setFontSize, setFont, setColor } from "../../../../store/slices/textStylesSlice";
import { useRef } from "react";
const availableFonts = [
  'Arial', 'AlexBrushRegular', 'AsapCondensedBlack', 'AsapCondensedBlackItalic', 'AsapCondensedBold', 'AsapCondensedBoldItalic',
  'AsapCondensedExtraLightItalic', 'AsapCondensedItalic', 'AsapCondensedMediumItalic', 'AsapCondensedRegular', 'AsapCondensedSemiBold',
  'CaveatBold', 'CaveatRegular', 'Caveat', 'CinzelBlack', 'CinzelBold', 'Cinzel', 'DancingScript', 'DancingScriptBold',
  'DancingScriptSemiBold', 'GFSDidotRegular', 'Inter', 'InterBold', 'InterExtraLight', 'LatoBlack', 'LatoBlackItalic',
  'LatoItalic', 'LatoLight', 'LatoLightItalic', 'LatoRegular', 'LobsterRegular', 'Oswald', 'OswaldBold', 'OswaldExtraLight',
  'OswaldLight', 'OswaldMedium', 'OswaldRegular', 'PacificoRegular', 'PlayfairDisplayBlack', 'PlayfairDisplayBoldItalic',
  'PlayfairDisplayItalic', 'PlayfairDisplayMedium', 'PlayfairDisplayMediumItalic', 'PlayfairDisplayRegular', 'PlayfairDisplaySemiBold',
  'PlayfairDisplaySemiBoldItalic', 'PTSansNarrowBold', 'PTSansNarrowRegular', 'PTSerifBold', 'PTSerifBoldItalic', 'PTSerifItalic',
  'PTSerifRegular', 'RobotoCondensedBold', 'RobotoCondensedBoldItalic', 'RobotoCondensedItalic', 'RobotoCondensedLight', 
  'RobotoCondensedLightItalic', 'RobotoCondensedRegular', 'TajawalBlack', 'TajawalBold', 'TajawalExtraBold', 'TajawalExtraLight',
  'TajawalLight', 'TajawalMedium', 'TajawalRegular', 'Vollkorn', 'VollkornBlack', 'VollkornBlackItalic', 'VollkornBold',
  'VollkornBoldItalic', 'VollkornExtraBold', 'VollkornExtraBoldItalic', 'VollkornItalic', 'VollkornMedium', 'VollkornMediumItalic',
  'VollkornRegular', 'VollkornSemiBold', 'VollkornSemiBoldItalic'
];



function PDFForm({ next , PdfData, textBoxData, mouseClickTagData}) {
  const [form] = Form.useForm();
  let pageNumber = mouseClickTagData?.pageNumber;
  let index = mouseClickTagData?.index;
  const { font_size = "10",
    font_type = "Arial", font_color = "#000000" } = textBoxData?.[pageNumber]?.[index] || {};

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [textStyle, setTextStyle] = useState({
    color: "#000000",
    fontSize: "10",
    font: "Arial",
    fontStyle: "normal",
  });
  const defaultColor = "#000000";
 
  const dispatch = useDispatch();
  
  const { fontSize, font, color } = useSelector((state) => state.textStyle);

  const handleFontChange = (selectedFont) => {
    setTextStyle({
      ...textStyle,
      font: selectedFont,
    });
    dispatch(setFont(selectedFont));
  };

  useEffect(()=>{
    if(PdfData.font_size && PdfData.font_color && PdfData.font_type){
      setTextStyle({
        ...textStyle,
        fontSize: PdfData.font_size,
      })
      setTextStyle({
        ...textStyle,
        color: PdfData.font_color || defaultColor,
      });
      setTextStyle({
        ...textStyle,
        font: PdfData.font_type,
      });
    }
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log(font_color, font_size, font_type);
    form.setFieldsValue({
      font: font_type,
      font_size: font_size,
      color: font_color,
    });
  }, [textBoxData]);

  const onFinish = (values) => {
    if(font == null)
    {
      notification.error({
        message: "Please Select Font style!",
      });
      return;
    }

    if(fontSize===null)
    {

      notification.error({
        message: "Please Enter Font size!",
        description: "Font size should be in between 0 to 150.",
      });
      return;
    }

    notification.success({ message: "PDF inserted successfully!" });
    next()
  };

  return (
    <div className="upload-form-container">
      <Form
        form={form}
        name="upload-pdf-form-header-text-form"
        onFinish={onFinish}
      >
        <Space direction={"vertical"} size={5} block={true}>
          <Space
            direction={direction(windowWidth)}
            size={windowWidth >= 576 ? 20 : 0}
            block={true}
          >
          </Space>
          <div className="form-header-text">Set Colour & Text Settings</div>
          <Form.Item label="Select Font : " name="font">
            <GlobalFonts />
            <FontDropdown
              fonts={availableFonts}
              selectedFont={font}
              onChange={handleFontChange}
            />
          </Form.Item>
          <Form.Item
            label="Select Font Size : "
            name="font_size"
          >
            <Input
              type="number"
              placeholder="Enter Font Size"
              onChange={(e) => {
                dispatch(setFontSize(e?.target?.value))
              }}
              defaultValue={Number(fontSize)}
            />
          </Form.Item>
          <Form.Item
            label={"Select Colour : "}
            name="color"
          >
            <ColorPicker
              format={"hex"}
              onFormatChange={"hex"}
              defaultValue={color}
              onChange={(e) => {
                setTextStyle({
                  ...textStyle,
                  color: e?.metaColor?.toHexString(),
                });
                dispatch(setColor(e?.metaColor?.toHexString()));
              }}
            />
          </Form.Item>

          <Button
            className="prev-button"
            // onClick={() => {
            //     pdfform.submit();
            // }}
            onClick={() => {next()}}
          >
            Next
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default PDFForm;
