import React, { useEffect, useState } from "react";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Tag, Typography, notification, Menu, Dropdown } from "antd";
import moment from "moment";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import {  setCurrentStepPdf, setPdfId } from "../../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import "./PdfRowlistTable.css";

function PdfRowlistTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [guestList, setGuestList] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_DEV_URL + "api/completed-guest-task-pdf/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        const apiResponse = res.data.data;
        if (Array.isArray(apiResponse)) {
          setGuestList(apiResponse);
        } else {
          setGuestList([]);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          notification.error({ message: err });
        }
      });
  }, [id, navigate]);

  const navigateToStepTwo = () => {
    dispatch(setPdfId(id));
    dispatch(setCurrentStepPdf(1));
    navigate("/edit-pdf");
  };

  const data = guestList?.map((guest, index) => ({
    key: index,
    id: guest.id,
    guestName: guest.main_name,
    tag1_value: guest.tag1_value,
    completedTime: moment.utc(guest.time, "HH:mm:ss").local().format("hh:mm A"),
    completedDate: guest.date,
    status: "Completed",
    action: "Download",
  }));

  const handleDownloadPDFsAsIndividuals = async (rec_id, tag1_value) => {
    if (!rec_id) {
      notification.error({ message: "Invalid ID", description: "Please provide a valid PDF ID." });
      return;
    }
  
    setIsDownloading(true);
    notification.success({
      message: "PDF File Download Started",
      description: "Please wait while the PDF file is being downloaded.",
    });
  
    try {
      const url = `${process.env.REACT_APP_DEV_URL}api/download_pdf/?id=${rec_id}`;
  
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("auth_token")}` },
      });
      
      let filePath = response.data.pdf_path;

      filePath = filePath.replace(/\\/g, "/");
      filePath = filePath.split("backend")[1];
      filePath = filePath?.startsWith("/") ? filePath?.substring(1) : filePath;

      let fileUrl = new URL(filePath, process.env.REACT_APP_DEV_URL).href;

      const pdfResponse = await fetch(fileUrl, {
        method: 'GET'
      });
  
      const blob = await pdfResponse.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      // const tag1Entry = tag1Values.find(entry => entry.processed_pdf_id === parseInt(rec_id));
      const fileName = tag1_value ? `${tag1_value}-${rec_id}.pdf` : "downloaded_file.pdf";

      link.href = urlBlob;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(urlBlob);
      document.body.removeChild(link);
  
    } catch (err) {
      console.error("Download error:", err);
      notification.error({ message: "Download Failed", description: "Could not download the file." });
    }finally{
      setIsDownloading(false);
    }
  };

  
  const handleDownloadOptionClick = async (e) => {
    const url = `${process.env.REACT_APP_DEV_URL}api/download_pdf/?is_bulk_download=true&id=${id}`;
  
    axios({
      url: url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
    .then(async (response) => {
    
      let filePath = response.data;
  
     
      filePath = filePath.replace(/\\/g, "/"); // Replace backslashes with forward slashes
      filePath = filePath.split("backend")[1]; // Remove the base directory part
      filePath = filePath?.startsWith("/") ? filePath.substring(1) : filePath; // Remove leading slash if present
  
      const fileUrl = new URL(filePath, process.env.REACT_APP_DEV_URL).href;
  

      const pdfResponse = await fetch(fileUrl, {
        headers: { Authorization: `Bearer ${localStorage.getItem("auth_token")}` },
      });
  
      if (!pdfResponse.ok) {
        throw new Error(`Failed to fetch file: ${pdfResponse.statusText}`);
      }
  
      const blob = await pdfResponse.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.download = filePath.split('/').pop(); // Use the original file name
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloading(false);
    })
    .catch((err) => {
      console.log(err.message);
      if (err.response && err.response.status === 400) {
        notification.error({ message: "New PDF's are under process can not download zip now!!" });
      } else {
        notification.error({ message: err.message });
      }
      setIsDownloading(false);
    });
  };

  const menu = (
    <Menu onClick={handleDownloadOptionClick}>
      <Menu.Item key="zip">Download as ZIP</Menu.Item>
    </Menu>
  );

  const handleDownloadCSV = () => {
    axios({
      url:
        process.env.REACT_APP_DEV_URL +
        "api/completed-guest-pdfs-filename-csv/" +
        id,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const a = document.createElement("a");
        a.href = url;
        a.download = "Guest Name & PDF File Name.csv";
        a.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          notification.error({ message: "Your new PDF is processing and the previous pdfs are deleted so no csv is found!!" });
        }
        else{
          notification.error({ message: "Something went wrong!"});
        }
      });
  };
  const isMainPdfDateOld = (data) => {
    if (data && data.length > 0) {
    
      const parsedDate = moment(data[0].main_pdf_date, "DD-MM-YYYY");
      const thirtyDaysAgo = moment().subtract(30, "days");

      const isDateOld = parsedDate.isBefore(thirtyDaysAgo);

      return isDateOld;
    }
    return false; 
  };

  const columns = [
    {
      title: "Names",
      dataIndex: "tag1_value",
      render: (tag1_value, record) => (
        <>
          <div className="pdf-details">
            <Typography.Text>{tag1_value}</Typography.Text>
          </div>
        </>
      ),
    },
    {
      title: "Complete Time",
      dataIndex: "completedTime",
    },
    {
      title: "Complete Date",
      dataIndex: "completedDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: () => (
        <>
          <Tag color={"success"}>Success</Tag>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <>
            <Button
              className="table-download-btn"
              onClick={() => {
                handleDownloadPDFsAsIndividuals(record.id, record.tag1_value);
              }}
            >
              <DownloadOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {isDownloading && <div className="blur-overlay"></div>}
      <div className="guestlist-container">
        <div className="title-text">List of Pdfs</div>
        <div className="buttons">
        {isMainPdfDateOld(data) ? null : (
            <Button
              className="upload-csv"
              icon={<UploadOutlined />}
              onClick={navigateToStepTwo}
            >
              Upload New CSV
            </Button>
          )}
          <Button className="download" onClick={handleDownloadCSV}>
            <DownloadOutlined />
            Download CSV
          </Button>
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button className="download">
              <DownloadOutlined /> Download All Pdfs
            </Button>
          </Dropdown>
        </div>
        <div className="content-table">
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </>
  );
}

export default PdfRowlistTable;
