import { createGlobalStyle } from 'styled-components';
import AlexBrushRegular from '../../../../fonts/AlexBrushRegular.ttf';
import AsapCondensedBlack from '../../../../fonts/AsapCondensedBlack.ttf';
import AsapCondensedBlackItalic from '../../../../fonts/AsapCondensedBlackItalic.ttf';
import AsapCondensedBold from '../../../../fonts/AsapCondensedBold.ttf';
import AsapCondensedBoldItalic from '../../../../fonts/AsapCondensedBoldItalic.ttf';
import AsapCondensedExtraLightItalic from '../../../../fonts/AsapCondensedExtraLightItalic.ttf';
import AsapCondensedItalic from '../../../../fonts/AsapCondensedItalic.ttf';
import AsapCondensedMediumItalic from '../../../../fonts/AsapCondensedMediumItalic.ttf';
import AsapCondensedRegular from '../../../../fonts/AsapCondensedRegular.ttf';
import AsapCondensedSemiBold from '../../../../fonts/AsapCondensedSemiBold.ttf';
import CaveatBold from '../../../../fonts/CaveatBold.ttf';
import CaveatRegular from '../../../../fonts/CaveatRegular.ttf';
import Caveat from '../../../../fonts/Caveat.ttf';
import CinzelBlack from '../../../../fonts/CinzelBlack.ttf';
import CinzelBold from '../../../../fonts/CinzelBold.ttf';
import Cinzel from '../../../../fonts/Cinzel.ttf';
import DancingScript from '../../../../fonts/DancingScript.ttf';
import DancingScriptBold from '../../../../fonts/DancingScriptBold.ttf';
import DancingScriptSemiBold from '../../../../fonts/DancingScriptSemiBold.ttf';
import GFSDidotRegular from '../../../../fonts/GFSDidotRegular.ttf';
import Inter from '../../../../fonts/Inter.ttf';
import InterBold from '../../../../fonts/InterBold.ttf';
import InterExtraLight from '../../../../fonts/InterExtraLight.ttf';
import LatoBlack from '../../../../fonts/LatoBlack.ttf';
import LatoBlackItalic from '../../../../fonts/LatoBlackItalic.ttf';
import LatoItalic from '../../../../fonts/LatoItalic.ttf';
import LatoLight from '../../../../fonts/LatoLight.ttf';
import LatoLightItalic from '../../../../fonts/LatoLightItalic.ttf';
import LatoRegular from '../../../../fonts/LatoRegular.ttf';
import LobsterRegular from '../../../../fonts/LobsterRegular.ttf';
import Oswald from '../../../../fonts/Oswald.ttf';
import OswaldBold from '../../../../fonts/OswaldBold.ttf';
import OswaldExtraLight from '../../../../fonts/OswaldExtraLight.ttf';
import OswaldLight from '../../../../fonts/OswaldLight.ttf';
import OswaldMedium from '../../../../fonts/OswaldMedium.ttf';
import OswaldRegular from '../../../../fonts/OswaldRegular.ttf';
import PacificoRegular from '../../../../fonts/PacificoRegular.ttf';
import PlayfairDisplayBlack from '../../../../fonts/PlayfairDisplayBlack.ttf';
import PlayfairDisplayBoldItalic from '../../../../fonts/PlayfairDisplayBoldItalic.ttf';
import PlayfairDisplayItalic from '../../../../fonts/PlayfairDisplayItalic.ttf';
import PlayfairDisplayMedium from '../../../../fonts/PlayfairDisplayMedium.ttf';
import PlayfairDisplayMediumItalic from '../../../../fonts/PlayfairDisplayMediumItalic.ttf';
import PlayfairDisplayRegular from '../../../../fonts/PlayfairDisplayRegular.ttf';
import PlayfairDisplaySemiBold from '../../../../fonts/PlayfairDisplaySemiBold.ttf';
import PlayfairDisplaySemiBoldItalic from '../../../../fonts/PlayfairDisplaySemiBoldItalic.ttf';
import PTSansNarrowBold from '../../../../fonts/PTSansNarrowBold.ttf';
import PTSansNarrowRegular from '../../../../fonts/PTSansNarrowRegular.ttf';
import PTSerifBold from '../../../../fonts/PTSerifBold.ttf';
import PTSerifBoldItalic from '../../../../fonts/PTSerifBoldItalic.ttf';
import PTSerifItalic from '../../../../fonts/PTSerifItalic.ttf';
import PTSerifRegular from '../../../../fonts/PTSerifRegular.ttf';
import RobotoCondensedBold from '../../../../fonts/RobotoCondensedBold.ttf';
import RobotoCondensedBoldItalic from '../../../../fonts/RobotoCondensedBoldItalic.ttf';
import RobotoCondensedItalic from '../../../../fonts/RobotoCondensedItalic.ttf';
import RobotoCondensedLight from '../../../../fonts/RobotoCondensedLight.ttf';
import RobotoCondensedLightItalic from '../../../../fonts/RobotoCondensedLightItalic.ttf';
import RobotoCondensedRegular from '../../../../fonts/RobotoCondensedRegular.ttf';
import TajawalBlack from '../../../../fonts/TajawalBlack.ttf';
import TajawalBold from '../../../../fonts/TajawalBold.ttf';
import TajawalExtraBold from '../../../../fonts/TajawalExtraBold.ttf';
import TajawalExtraLight from '../../../../fonts/TajawalExtraLight.ttf';
import TajawalLight from '../../../../fonts/TajawalLight.ttf';
import TajawalMedium from '../../../../fonts/TajawalMedium.ttf';
import TajawalRegular from '../../../../fonts/TajawalRegular.ttf';
import Vollkorn from '../../../../fonts/Vollkorn.ttf';
import VollkornBlack from '../../../../fonts/VollkornBlack.ttf';
import VollkornBlackItalic from '../../../../fonts/VollkornBlackItalic.ttf';
import VollkornBold from '../../../../fonts/VollkornBold.ttf';
import VollkornBoldItalic from '../../../../fonts/VollkornBoldItalic.ttf';
import VollkornExtraBold from '../../../../fonts/VollkornExtraBold.ttf';
import VollkornExtraBoldItalic from '../../../../fonts/VollkornExtraBoldItalic.ttf';
import VollkornItalic from '../../../../fonts/VollkornItalic.ttf';
import VollkornMedium from '../../../../fonts/VollkornMedium.ttf';
import VollkornMediumItalic from '../../../../fonts/VollkornMediumItalic.ttf';
import VollkornRegular from '../../../../fonts/VollkornRegular.ttf';
import VollkornSemiBold from '../../../../fonts/VollkornSemiBold.ttf';
import VollkornSemiBoldItalic from '../../../../fonts/VollkornSemiBoldItalic.ttf';

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'AlexBrushRegular';
    src: url(${AlexBrushRegular}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedBlack';
    src: url(${AsapCondensedBlack}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedBlackItalic';
    src: url(${AsapCondensedBlackItalic}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedBold';
    src: url(${AsapCondensedBold}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedBoldItalic';
    src: url(${AsapCondensedBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedExtraLightItalic';
    src: url(${AsapCondensedExtraLightItalic}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedItalic';
    src: url(${AsapCondensedItalic}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedMediumItalic';
    src: url(${AsapCondensedMediumItalic}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedRegular';
    src: url(${AsapCondensedRegular}) format('truetype');
  }

  @font-face {
    font-family: 'AsapCondensedSemiBold';
    src: url(${AsapCondensedSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'CaveatBold';
    src: url(${CaveatBold}) format('truetype');
  }

  @font-face {
    font-family: 'CaveatRegular';
    src: url(${CaveatRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Caveat';
    src: url(${Caveat}) format('truetype');
  }

  @font-face {
    font-family: 'CinzelBlack';
    src: url(${CinzelBlack}) format('truetype');
  }

  @font-face {
    font-family: 'CinzelBold';
    src: url(${CinzelBold}) format('truetype');
  }

  @font-face {
    font-family: 'Cinzel';
    src: url(${Cinzel}) format('truetype');
  }

  @font-face {
    font-family: 'DancingScript';
    src: url(${DancingScript}) format('truetype');
  }

  @font-face {
    font-family: 'DancingScriptBold';
    src: url(${DancingScriptBold}) format('truetype');
  }

  @font-face {
    font-family: 'DancingScriptSemiBold';
    src: url(${DancingScriptSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'GFSDidotRegular';
    src: url(${GFSDidotRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    src: url(${Inter}) format('truetype');
  }

  @font-face {
    font-family: 'InterBold';
    src: url(${InterBold}) format('truetype');
  }

  @font-face {
    font-family: 'InterExtraLight';
    src: url(${InterExtraLight}) format('truetype');
  }
  @font-face {
    font-family: 'LatoBlack';
    src: url(${LatoBlack}) format('truetype');
  }

  @font-face {
    font-family: 'LatoBlackItalic';
    src: url(${LatoBlackItalic}) format('truetype');
  }

  @font-face {
    font-family: 'LatoItalic';
    src: url(${LatoItalic}) format('truetype');
  }

  @font-face {
    font-family: 'LatoLight';
    src: url(${LatoLight}) format('truetype');
  }

  @font-face {
    font-family: 'LatoLightItalic';
    src: url(${LatoLightItalic}) format('truetype');
  }

  @font-face {
    font-family: 'LatoRegular';
    src: url(${LatoRegular}) format('truetype');
  }

  @font-face {
    font-family: 'LobsterRegular';
    src: url(${LobsterRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Oswald';
    src: url(${Oswald}) format('truetype');
  }

  @font-face {
    font-family: 'OswaldBold';
    src: url(${OswaldBold}) format('truetype');
  }

  @font-face {
    font-family: 'OswaldExtraLight';
    src: url(${OswaldExtraLight}) format('truetype');
  }

  @font-face {
    font-family: 'OswaldLight';
    src: url(${OswaldLight}) format('truetype');
  }

  @font-face {
    font-family: 'OswaldMedium';
    src: url(${OswaldMedium}) format('truetype');
  }

  @font-face {
    font-family: 'OswaldRegular';
    src: url(${OswaldRegular}) format('truetype');
  }

  @font-face {
    font-family: 'PacificoRegular';
    src: url(${PacificoRegular}) format('truetype');
  }

  @font-face {
    font-family: 'PlayfairDisplayBlack';
    src: url(${PlayfairDisplayBlack}) format('truetype');
  }

  @font-face {
    font-family: 'PlayfairDisplayBoldItalic';
    src: url(${PlayfairDisplayBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: 'PlayfairDisplayItalic';
    src: url(${PlayfairDisplayItalic}) format('truetype');
  }

  @font-face {
    font-family: 'PlayfairDisplayMedium';
    src: url(${PlayfairDisplayMedium}) format('truetype');
  }

  @font-face {
    font-family: 'PlayfairDisplayMediumItalic';
    src: url(${PlayfairDisplayMediumItalic}) format('truetype');
  }

  @font-face {
    font-family: 'PlayfairDisplayRegular';
    src: url(${PlayfairDisplayRegular}) format('truetype');
  }

  @font-face {
    font-family: 'PlayfairDisplaySemiBold';
    src: url(${PlayfairDisplaySemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'PlayfairDisplaySemiBoldItalic';
    src: url(${PlayfairDisplaySemiBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: 'PTSansNarrowBold';
    src: url(${PTSansNarrowBold}) format('truetype');
  }

  @font-face {
    font-family: 'PTSansNarrowRegular';
    src: url(${PTSansNarrowRegular}) format('truetype');
  }

  @font-face {
    font-family: 'PTSerifBold';
    src: url(${PTSerifBold}) format('truetype');
  }

  @font-face {
    font-family: 'PTSerifBoldItalic';
    src: url(${PTSerifBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: 'PTSerifItalic';
    src: url(${PTSerifItalic}) format('truetype');
  }

  @font-face {
    font-family: 'PTSerifRegular';
    src: url(${PTSerifRegular}) format('truetype');
  }

  @font-face {
    font-family: 'RobotoCondensedBold';
    src: url(${RobotoCondensedBold}) format('truetype');
  }

  @font-face {
    font-family: 'RobotoCondensedBoldItalic';
    src: url(${RobotoCondensedBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: 'RobotoCondensedItalic';
    src: url(${RobotoCondensedItalic}) format('truetype');
  }

  @font-face {
    font-family: 'RobotoCondensedLight';
    src: url(${RobotoCondensedLight}) format('truetype');
  }

  @font-face {
    font-family: 'RobotoCondensedLightItalic';
    src: url(${RobotoCondensedLightItalic}) format('truetype');
  }

  @font-face {
    font-family: 'RobotoCondensedRegular';
    src: url(${RobotoCondensedRegular}) format('truetype');
  }

  @font-face {
    font-family: 'TajawalBlack';
    src: url(${TajawalBlack}) format('truetype');
  }

  @font-face {
    font-family: 'TajawalBold';
    src: url(${TajawalBold}) format('truetype');
  }

  @font-face {
    font-family: 'TajawalExtraBold';
    src: url(${TajawalExtraBold}) format('truetype');
  }

  @font-face {
    font-family: 'TajawalExtraLight';
    src: url(${TajawalExtraLight}) format('truetype');
  }

  @font-face {
    font-family: 'TajawalLight';
    src: url(${TajawalLight}) format('truetype');
  }

  @font-face {
    font-family: 'TajawalMedium';
    src: url(${TajawalMedium}) format('truetype');
  }

  @font-face {
    font-family: 'TajawalRegular';
    src: url(${TajawalRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Vollkorn';
    src: url(${Vollkorn}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornBlack';
    src: url(${VollkornBlack}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornBlackItalic';
    src: url(${VollkornBlackItalic}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornBold';
    src: url(${VollkornBold}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornBoldItalic';
    src: url(${VollkornBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornExtraBold';
    src: url(${VollkornExtraBold}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornExtraBoldItalic';
    src: url(${VollkornExtraBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornItalic';
    src: url(${VollkornItalic}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornMedium';
    src: url(${VollkornMedium}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornMediumItalic';
    src: url(${VollkornMediumItalic}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornRegular';
    src: url(${VollkornRegular}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornSemiBold';
    src: url(${VollkornSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'VollkornSemiBoldItalic';
    src: url(${VollkornSemiBoldItalic}) format('truetype');
  }

`;

export default GlobalFonts;
