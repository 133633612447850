import React from "react";
import { UserSwitchOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { Button, Form, Input, Row, Col } from "antd";

const ForgotPasswordTab = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email id!" },
              { type: "email", message: "Please enter valid email id!" },
            ]}
          >
            <Input
              type="email"
              className="login-input-field"
              placeholder="Email"
              prefix={<UserSwitchOutlined className="icon-color" />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="flex-row" style={{ float: "right" }}>
            <NavLink
              to="/login"
              className={"login-txt"}
              style={{ color: "var(--tab-color)", marginBottom: "30px" }}
            >
              Already have password ?
            </NavLink>
          </div>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-btn">
              Forgot Password
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ForgotPasswordTab;
