// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container{
    height: 82px;
    padding: 0px 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* backdrop-filter: blur(5px); */
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    z-index: 2;
    top:0
}
.logout-btn{
    background-color: white;
    color:var(--primary-color);
    border:1px solid var(--primary-color);
    font-family: var(--poppins-font);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    width: 114px;
    height: 40px;
}
.logout-btn:hover{
    background-color: var(--primary-color);
    border:1px solid var(--primary-color) !important;
    color:white !important;
}


@media only screen and (max-width: 992px) {
    .header-container{
        padding: 0px 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Header/header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,gCAAgC;IAChC,uBAAuB;IACvB,2CAA2C;IAC3C,gBAAgB;IAChB,UAAU;IACV;AACJ;AACA;IACI,uBAAuB;IACvB,0BAA0B;IAC1B,qCAAqC;IACrC,gCAAgC;IAChC,4CAA4C;IAC5C,YAAY;IACZ,YAAY;AAChB;AACA;IACI,sCAAsC;IACtC,gDAAgD;IAChD,sBAAsB;AAC1B;;;AAGA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".header-container{\n    height: 82px;\n    padding: 0px 100px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    /* backdrop-filter: blur(5px); */\n    background-color: white;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n    position: sticky;\n    z-index: 2;\n    top:0\n}\n.logout-btn{\n    background-color: white;\n    color:var(--primary-color);\n    border:1px solid var(--primary-color);\n    font-family: var(--poppins-font);\n    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);\n    width: 114px;\n    height: 40px;\n}\n.logout-btn:hover{\n    background-color: var(--primary-color);\n    border:1px solid var(--primary-color) !important;\n    color:white !important;\n}\n\n\n@media only screen and (max-width: 992px) {\n    .header-container{\n        padding: 0px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
