// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container{
    text-align: center;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.form-container{
    width: 480px;
}
.icon-color{
    color:var(--tab-color) !important
}
.flex-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
:where(.css-dev-only-do-not-override-k7429z).ant-form-item .ant-form-item-control-input{
    min-height: unset;
}
:where(.css-dev-only-do-not-override-k7429z).ant-form-item .ant-form-item-explain-error{
    text-align: left;
}
.login-btn{
    min-width: 120px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
}
.login-btn:hover{
    background-color: white !important;
    color:var(--primary-color) !important;
}
.login-input-field{
    height: 40px;
}
.login-txt{
    font-family: var(--poppins-font);
    font-size: 16px;
}
.tab-container{
    background-color: var(--tab-back-color);
    max-width: 480px;
    height: 46px;
    padding: 0px 20px;
    margin-bottom: 30px;
    line-height:40px;
}
.tab{
    color:var(--tab-color);
}

`, "",{"version":3,"sources":["webpack://./src/components/login-register/login.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI;AACJ;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,uBAAuB;AAC3B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,gCAAgC;IAChC,sCAAsC;IACtC,4CAA4C;IAC5C,kBAAkB;AACtB;AACA;IACI,kCAAkC;IAClC,qCAAqC;AACzC;AACA;IACI,YAAY;AAChB;AACA;IACI,gCAAgC;IAChC,eAAe;AACnB;AACA;IACI,uCAAuC;IACvC,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".login-container{\n    text-align: center;\n    height: calc(100vh - 100px);\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}\n.form-container{\n    width: 480px;\n}\n.icon-color{\n    color:var(--tab-color) !important\n}\n.flex-row{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-start;\n}\n:where(.css-dev-only-do-not-override-k7429z).ant-form-item .ant-form-item-control-input{\n    min-height: unset;\n}\n:where(.css-dev-only-do-not-override-k7429z).ant-form-item .ant-form-item-explain-error{\n    text-align: left;\n}\n.login-btn{\n    min-width: 120px;\n    background: var(--primary-color);\n    border: 1px solid var(--primary-color);\n    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);\n    border-radius: 2px;\n}\n.login-btn:hover{\n    background-color: white !important;\n    color:var(--primary-color) !important;\n}\n.login-input-field{\n    height: 40px;\n}\n.login-txt{\n    font-family: var(--poppins-font);\n    font-size: 16px;\n}\n.tab-container{\n    background-color: var(--tab-back-color);\n    max-width: 480px;\n    height: 46px;\n    padding: 0px 20px;\n    margin-bottom: 30px;\n    line-height:40px;\n}\n.tab{\n    color:var(--tab-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
