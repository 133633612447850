import React from "react";
import "./footer.css";

const Footer = () => {
  const today = new Date();
  return (
    <div className="footer">
      Copyright &#169; {today.getFullYear()} Wetales All rights Reserved.
    </div>
  );
};

export default Footer;
