import { Typography, Row } from "antd";
import React from "react";

import StatusCard from "./StatusCard";

const RenderVideoStatus = () => {
  return (
    <div className="upload-main-container">
      <Typography.Text className="upload-header-text">
        Render Video status
      </Typography.Text>
      <Row style={{ marginTop: "20px" }} gutter={[20, 20]}>
        <StatusCard />
      </Row>
    </div>
  );
};

export default RenderVideoStatus;
