import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateRoute() {
  const { access_token } = useSelector((state) => state.auth);
  if (access_token) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
}