import React  from 'react'
import { useSelector , useDispatch } from 'react-redux';
import { setCurrentStepPdf } from '../../store/slices/authSlice';
import { Typography } from "antd";
import Stepper from './stepper/Stepper';
import RenderPDFStatus from './RenderPDFStatus';

const UploadPDF = () => {

  const dispatch = useDispatch();
  const currentPdfstep = useSelector((state)=>state.auth);

  const getCurrentStepText = {
    0: "Upload PDF",
    1: "Upload CSV File"
  };

  return (
        <>
        <div className="upload-main-container">
              <Typography.Text className="upload-header-text">
                {getCurrentStepText[currentPdfstep]}
              </Typography.Text>
              <Stepper
                currentStepsIndex={currentPdfstep.currentStepPdf}
                setCurrentStepsIndex={(step) => dispatch(setCurrentStepPdf(step))}
              />
        </div>
            {(currentPdfstep.currentStepPdf === 0 || currentPdfstep.currentStepPdf === 1) && <RenderPDFStatus />}
          </>
    
  )
}

export default UploadPDF
