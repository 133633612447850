import React, { useState } from "react";
import { Typography } from "antd";
import Stepper from "./stepper/Stepper";
import RenderVideoStatus from "./RenderVideoStatus";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../store/slices/authSlice";

const UploadVideo = () => {
  const { currentStep } = useSelector((state) => state.auth);
  const [ currentStepsIndex, setCurrentStepsIndex ] = useState(currentStep);
  const dispatch = useDispatch();

  const getCurrentStepText = {
    0: "Upload Video",
    1: "Upload CSV File",
    2: "Invoice Details",
  };
  return (
    <>
      <div className="upload-main-container">
        <Typography.Text className="upload-header-text">
          {getCurrentStepText[currentStep]}
        </Typography.Text>
        <Stepper
          currentStepsIndex={currentStepsIndex}
          setCurrentStepsIndex={setCurrentStepsIndex}
          current={currentStep}
          setCurrent={() => dispatch(setCurrentStep(currentStep))}
        />
      </div>
      {(currentStep === 0 || currentStep === 1) && <RenderVideoStatus />}
    </>
  );
};

export default UploadVideo;
