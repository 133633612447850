import React, { useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import logo from "../../../../assets/images/fulllogo.svg";
import { useNavigate } from "react-router-dom";

const PaymentButton = ({videoId}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    // script.onload = initializeRazorpay;
    document.body.appendChild(script);
  }, []);

  const storedEmail = localStorage.getItem("userEmail");
  let razorpayApiKey = process.env.REACT_APP_Razorpay_API_KEY;
  const emailList = process.env.REACT_APP_EMAIL_LIST.split(",");
  if (emailList.includes(storedEmail)) {
    razorpayApiKey = process.env.REACT_APP_Razorpay_TEST_API_KEY;
  }
  const navigate = useNavigate();
  const handlePaymentButtonClick = (e) => {
    axios
      .post(
        process.env.REACT_APP_DEV_URL + "api/payment/create_order",
        {"video_id": videoId},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        const options = {
          key: razorpayApiKey, // Enter the Key ID generated from the Dashboard
          amount: parseFloat(res.data.amount),
          name: "WeTales", // your business name
          description: "Test Transaction",
          image: { logo },
          order_id: res.data.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          prefill: {
            email: storedEmail,
          },
          notes: {
            address: "Razorpay Corporate Office",
            reciept_id: res.data.receipt,
          },
          theme: {
            color: "#0988FE",
          },
          handler: function (response) {
            if (
              "razorpay_payment_id" in response &&
              "razorpay_order_id" in response &&
              "razorpay_signature" in response
            ) {
              window.location.reload(true);
            }
          },
        };
        window.rzp = new window.Razorpay(options);
        window.rzp.open();

        e.preventDefault();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.detail) {
          // Display the error message to the user
          notification.error({
            message: "An error occurred while processing your Payment.",
          });
        } else {
          // Handle other types of errors, or provide a generic error message
          notification.error({
            message: "An error occurred while processing your Payment.",
          });
        }
      });
  };

  return (
    <div>
      <button
        className="payment-button"
        id="rzp-button1"
        onClick={handlePaymentButtonClick}
      >
        Make Payment
      </button>
    </div>
  );
};

export default PaymentButton;
