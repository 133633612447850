import React from "react";
import logo from "../../../assets/images/fulllogo.svg";
import { Button, Image } from "antd";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../../store/slices/authSlice";

const Header = () => {
  const navigate = useNavigate();
  const { isLogin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.clear();
    dispatch(resetState());
    navigate("/login");
  };;
  const currentPath = window.location.pathname;
  const navigateToDashboard = () => {
    navigate("/");
  };
  return (
    <>
      <div className="header-container">
        <Image src={logo} preview={false} onClick={navigateToDashboard}/>
        {currentPath !== '/login' && currentPath !== '/forgot-password' && (
        <Button className="logout-btn" onClick={logout}>
          Log out
        </Button>
        )}
      </div>
    </>
  );
};

export default Header;