import React from 'react'
import GuestlistTable from './GuestlistTable'
import Header from '../../layout/Header/Header'
import TaskResult from '../TaskResult'


function GuestListMain() {
  return (
    <div>
        <Header/>
        <TaskResult />
        <GuestlistTable/>
    </div>
  )
}

export default GuestListMain